@import "assets/scss/common.scss";

// fonts

@font-face {
  font-family: "Ebrima";
  src: url("assets/fonts/EBRIMA.TTF");
}

@font-face {
  font-family: "Ebrima Bold";
  src: url("assets/fonts/EBRIMABD.TTF");
}

// styles
* {
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

body {
  margin: 0 auto;
  padding: 0;
  background-color: #0A324D;
  font-family: "Ebrima";
  overflow-x: hidden;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  #root {
    @include screen_xl {
      //1720+
      font-size: 3.5vw;
    }
    @include screen_lg {
      //1720
      font-size: 5vw;
    }
    @include screen_md {
      // < 1290
      font-size: 5vw;
    }
    @include screen_sm {
      // < 860
      font-size: 8vw;
    }
    @include mobile_portrait {
      // < 481px
      font-size: 15vw;
    }
  }
}

// div {
//   border: 1px solid #ddd;
// }
