
.Wait__wrapper{
    position:fixed;
    z-index:1001;
  
    width:100vw;
    height:100vh;
    font-size:.8em;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:7.5em;
    border-radius: .15em;
    padding:.5em;
    color:white;

    .title-container {
        position: relative;      
        align-items: center;
        font-size: .892857143em; // Original is 67.2px, or 3.5vw (1920x1080 screen); Comes from body #root; This ratio works out to be 60px
        .title-logo{
          position:relative;
          left:50%;
          transform:translateX(-50%);
        }
        .logo {
          position: relative;
          top:0;
          left:50%;
          transform:translateX(-50%);
          height: 2em;
        }
        .title{
          position:relative;
          font-size:.4em;
          margin-bottom:1.2em;        
          justify-content: center;
          display:flex;
          width:100%;
          height:min-content;
        }
      }
      .message{
          font-size:.4em;
      }
    .avatar-layout{
        .avatar{
            width:100%;
            

        }
    }
}