.edit-text-field {
  padding: 1rem;
  border: 1px solid var(--clr-black);
  background: var(--clr-white);

  &__header {
    
  }

  &__label {
  }

  &__input {
    width: 100%;
    padding: 0.5rem 0;
    border: none;
    border-radius: 0;
    background: transparent;
    font-size: 18px;
    font-family: var(--ff-body);
    resize: none;
    overflow: hidden;
  }

  &__counter {
    text-align: right;

    &--full {
      color: var(--clr-danger);
    }
  }
}
