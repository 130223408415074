.create-loading {
    &__image {
        display: block;
        margin: 0 auto;
    }

    &__progress[value] {
        position: absolute;
        left: -200vw;
        opacity: 0;
    }

    &__progress-bar {
        position: relative;
        width: 100%;
        height: 1rem;
        background-color: #0003;
        overflow: hidden;
        border-radius: 0.75rem;

    }

    &__progress-value {
        position: absolute;
        max-width: 90%;
        height: 100%;
        background-color: #ff8c00;
        background-image: linear-gradient(
            -45deg,
            transparent 25%,
            #0002 25%, #0002 50%,
            transparent 50%, transparent 75%,
            #0002 75%, #0002
        );
        background-size: 1rem 1rem;
        transition: width 250ms linear;
        animation: stripes 2s linear infinite;
    }
}

@keyframes stripes {
    100% {
        background-position: -1rem 0px;
    }
}
