.ClassTitle__wrapper {
    align-self: center;
    width: 6em;
    .title_text {
        font-size: 0.35em;
        font-weight: 700;
        line-height: 2em; // relative to parent height *local font-size has halved it
        text-transform: uppercase;
        text-align: center;
        color: #00aac7;
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: all;
        &:hover {
            white-space: unset;
            overflow: unset;
            background-color: grey;
            color: white;
        }
    }
}
