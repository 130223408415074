@import "assets/scss/common.scss";

.gameModal__wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  @include mobile_portrait {
    // < 481px
    font-size: 0.5em;
  }
  .contentPlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("./images/brick_purple_large.png");
    background-repeat: no-repeat;
    background-size: contain;
    border: 0.04em solid #111111;

    .game {
      position: absolute;
      top: 0;
      //z-index: 1000;
      width: 100%;
      height: 100%;
      //pointer-events: none;
    }

    .game_play {
      .action_btn {
        transition: all 1s ease-in-out;
        position: absolute;
        bottom: 0.45em;
        right: 0.45em;
        width: 40%;
        height: 0.7em;
      }

      .preview {
        transition: all 1s ease-in-out;
        position: absolute;
        width: calc(60% - 0.45em);
        height: 65%;
        top: 0.3em;
        right: 0.45em;
        border: black 0.09em transparent;
        border-radius: 0.15em;
        .title-overlay {
          width: 75%;
          object-fit: contain;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: rgb(155, 131, 131);
          text-shadow: 0 0 0.25em #fff, 0 0 0.3em #fff, 0 0 0.5em #e60073, 0 0 0.2em #e60073, 0 0 0.1em #e60073,
            0 0 0.2em #e60073, 0 0 0.3em #e60073;
          font-size: 0.65em;
        }
        .game {
          z-index: 2;
          transition: all 1s ease-in-out;
          background-color: rgba(0, 0, 0, 0);
          display: flex;
          justify-content: center;
        }
        .vignette {
          position: absolute;
          top: 1px;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 40px 40px rgba(0, 0, 0, 0.2);
          z-index: 1;
          pointer-events: none;
        }
        video {
          //-webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);
          //mask-image: linear-gradient(to right, transparent 0%, black 50%);
          width: 100%;
          height: 100%;
          border-radius: 13px;
          position: absolute;
          object-fit: cover;
          transition: all 2s ease-in-out;
          filter: brightness(1);
        }
      }
      .skills {
        transition: all 1s ease-in-out;
        position: absolute;
        bottom: 0.45em;
        left: 0.45em;
        width: 30%;
        height: calc(40% - 0.6em);
      }
      .leaderboard {
        transition: all 1s ease-in-out;
        position: absolute;
        opacity: 1;
        top: 0.45em;
        left: 0.45em;
        width: 30%;
        height: calc(60% - 0.45em);
      }
      .avatar {
        transition: all 1s ease-in-out;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20%;
        margin-left: calc(50% - 10%);
        z-index: 2;
      }
    }
    .game_play.play {
      .preview {
        transition: all 1s ease-in-out;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        border: black 0.09em solid;
        border-radius: 0.15em;
        z-index: 1001;
      }
      video {
        transition: all 15s ease-in-out;
        filter: brightness(0.2);
      }
      .vignette {
        transition: all 1s ease-in-out;
        box-shadow: inset 0 0 5vw 5vw rgba(0, 0, 0, 1);
      }
      .leaderboard {
        transition: all 1s ease-in-out;
        left: -50%;
        opacity: 0;
      }
      .skills {
        transition: all 1s ease-in-out;
        left: -50%;
        opacity: 0;
      }
      .avatar {
        transition: all 3s ease-in-out;
        bottom: -20%;
        opacity: 0;
        z-index: 1010;
      }
      .action_btn {
        transition: all 1s ease-in-out;
        bottom: -20%;
        opacity: 0;
      }
      .game {
        z-index: 2;
        //transition: all 15s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.4);
        transform-origin: top;
      }
      iframe {
        width: 1360px !important;
        height: 780px;
        position: absolute;
      }
    }
  }
  .game__edit {
    .preview {
      width: calc(30% - 0.45em);
      height: 32.5%;
    }
    .editable {
      font-size: 0.25em;
      margin-left: 1em;
      color: white;
    }
    .stats {
      font-size: 0.25em;
      margin-left: 1em;
      color: white;
    }
  }
}
