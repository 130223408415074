.lesson-page {
  position: relative;
  z-index: 1;
  display: grid;
  max-width: 75rem;
  justify-content: center;
  padding: 0 0.5rem 8rem;
  margin: 0 auto;
  column-gap: 4rem;
  grid-template-areas:
    'banner'
    'title'
    'video'
    'right'
    'left'
  ;

  @media (min-width: 500px) {
    grid-template-areas:
      'banner banner'
      'title title'
      'video video'
      'left right'
    ;
    grid-template-columns: 1fr 16rem;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -50vw;
    width: 200vw;
    height: 100%;
    background: #fff8;
    content: "";
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    grid-area: title;
    text-transform: uppercase;
  }

  &__video {
    margin-bottom: 2rem;
    grid-area: video;
  }

  .lesson-section {
    &--expandable {
      border-bottom: 1px solid black;

      .section__title {
        cursor: pointer;
        list-style: none;

        &::after {
          --rotation: 0deg;

          position: absolute;
          top: 50%;
          right: 0;
          display: flex;
          width: 1em;
          height: 1em;
          align-items: center;
          justify-content: center;
          content: ">";
          transform: translateY(-50%) rotate(var(--rotation));
          transition: transform 150ms ease-in-out;
        }
      }

      &[open] {
        padding-bottom: 3rem;

        .section__title::after {
          --rotation: 90deg;
        }
      }
    }

    &__title {
      position: relative;
      padding: 1rem 0;
      margin: 0;
      font-size: 1.25rem;
      font-weight: 500;
    }

    p {
      white-space: pre-wrap;
    }
  }

  &__right-column {
    grid-area: right;
  }

  &__left-column {
    grid-area: left;
  }

  .bookmarks {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .bookmark {
    color: var(--clr-black);
  }
}
