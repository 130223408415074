@import 'assets/scss/common.scss';

.AudioCheck__wrapper {
    width: 9.6em;
    // height: 12.25em;
    height: 11.5em;
    background-color: white !important;
    border-radius: .15em;
    margin: -0.1em; // Fill the existing Popup modal

    .panel-title {
        font-size: 0.35em;
        color: #707070;
        font-family: "Ebrima Bold";
        padding-top: 1em;
        padding-left: 1em; 
    }

    .audio-input-section {
        position: absolute;
        top: 1.5em;
        left: 0.5em;

        .audio-img {
            background: url('./images/mic_black.png');
            background-size: contain;
            width: 1em;
            height: 1em;
            background-repeat: no-repeat;
            position: absolute;
            left: 0.1em;

        }

        .audio-text {
            position: absolute;
            top: 4em;
            color: black;
            font-size: 0.25em;
            width: 6em;
        }
        
        .audio-input-dropdown {
            position: absolute;
            left: 2em;
            top: 0.3em
        }

        .audio-input-slider {
            position: absolute;
            top: 1em;
            left: 2em;
            
        }
    }

    .audio-output-section {
        position: absolute;
        top: 4em;
        left: 0.5em;

        .speaker-img {
            background: url('./images/speaker_black.png');
            background-size: contain;
            width: 1em;
            height: 1em;
            background-repeat: no-repeat;
            position: absolute;
            left: 0.3em;

        }

        .speaker-text {
            position: absolute;
            top: 4em;
            color: black;
            font-size: 0.25em;
            width: 6em;
            left: 0.9em;
        }

        .audio-output-dropdown {
            position: absolute;
            left: 2em;
        }

        .audio-output-slider {
            position: absolute;
            top: 1em;
            left: 2em;
            
        }
    }

    .test-section {
        position: absolute;
        top: 3.5em;
        left: 2em;
        width: calc(100% - 3.5em);

        .test-text {
            position: absolute;
            color: black;
            font-size: 0.35em;
            width: 100%;
            text-align: center;
            left: -0.5em;
        }

        .submit-choices {
            background-color: #289b36;
            border: 0.15em solid #046811;
            border-radius: 0.6em;
            color: white;
            font-size: 0.3em;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
            font-family: "Ebrima";
            width: 7em;
            position: absolute;
            top: 5em;

            &:hover {
                background-color: #4bc059;
            }
    
            &:active {
                background-color: #21862d;
            }
        }

        .volume-img {
            background-size: contain !important;
            width: 3em;
            height: 1em;
            background-repeat: no-repeat !important;
            position: absolute;
            top: 1.52em;
            left: 2.275em;

            &.level0 {
                background: url('./images/audio_indicator_00.png');
            }

            &.level1 {
                background: url('./images/audio_indicator_01.png');
            }
            &.level2 {
                background: url('./images/audio_indicator_02.png');
            }

            &.level3 {
                background: url('./images/audio_indicator_03.png');
            }
            
            &.level4 {
                background: url('./images/audio_indicator_04.png');
            }
            
            &.level5 {
                background: url('./images/audio_indicator_05.png');
            }
            
            &.level6 {
                background: url('./images/audio_indicator_06.png');
            }
            
            &.level7 {
                background: url('./images/audio_indicator_07.png');
            }
            
            &.level8 {
                background: url('./images/audio_indicator_08.png');
            }
            
            &.level9 {
                background: url('./images/audio_indicator_09.png');
            }

            &.level10 {
                background: url('./images/audio_indicator_10.png');
            }
        }
    
    }

    .help-section {
        position: absolute;
        top: 6.75em;
        width: 100%;

        .help-img-left {
            background: url('./images/audiobtn_warning_normal.png');
            background-size: contain;
            width: 0.8em;
            height: 0.8em;
            background-repeat: no-repeat;
            position: absolute;
            left: 1.5em;
        }

        .help-img-right {
            background: url('./images/audiobtn_warning_normal.png');
            background-size: contain;
            width: 0.8em;
            height: 0.80em;
            background-repeat: no-repeat;
            position: absolute;
            right: 1.5em;
        }


        .help-title {
            position: absolute;
            color: black;
            font-size: 0.35em;
            width: 100%;
            text-align: center;
            top: 0.45em;
        }

        .help-items {
            position: absolute;
            color: black;
            font-size: 0.35em;
            width: 100%;
            text-align: center;
            top: 4em;

        }
    }

    /* Dropdown Button */
    .dropdown {
        position: relative;
        // display: inline-block;
        font-size: 0.25em;
        width: 20em;

        .dropbtn {
            background-color: white;
            color: black;
            padding: 0.5em;
            border: .1em solid black;
            cursor: pointer;
            border-radius: 0.5em;

            &:hover, &:focus {
                background-color: #f1f1f1;

            }

            .selected-device-name {
                font-weight: bold;
            }

            .dropbtn-symbol {
                position: absolute;
                right: 0.5em;
                color: #707070;
            }
        }
        
        /* Dropdown Content (Hidden by Default) */
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 2em;
            //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;

            &.show {
                display:block;
            }
        
            /* Items inside the dropdown */
            .dropdown-content-item {
                color: #707070;
                padding: 0.4em 0.5em;
                text-decoration: none;
                display: block;

                &.selected {
                    color: black;
                    font-weight: bold;
                }

                &:hover {
                    background-color: #ddd
                }

            }
        }
    }


    /*
    .audio-input-slider {
        // -webkit-appearance: none;
        width: 2em;
        height: 0.3em;
        font-size: 1em;
        padding: 0;
        border: none;
        //background: url('./images/slider_btn_normal.png');

        &:focus {
            background-color: red;
        }
    }
    */

    //https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
    input[type=range] {
        -webkit-appearance: none;
        width: 6em;
        height: 0.3em;
        font-size: 1em;
        padding: 0;
        border: none;
      }

      input[type=range]:focus {
        outline: none;
      }

      input[type=range]::-webkit-slider-runnable-track {

        width: 100%;
        height: 0.125em;
        cursor: pointer;
        //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #289b36;
        //border-radius: 1.3px;
        //border: 0.2px solid #010101;
      }

      input[type=range]::-webkit-slider-thumb {
        // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        // border: 1px solid #000000;
        height: 0.60em;
        width: 0.27em;
        border-radius: 1em;
        background: white;
        border: 0.04em solid #707070;
        /*
        background: url('./images/slider_btn_normal.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: inherit;
        */
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -0.25em;

        &:hover {
            border: 0.05em solid #707070;
        }

        &:active {
            background: #707070;

        }
      }

      input[type=range]:focus::-webkit-slider-runnable-track {
        background: #289b36;
      }


      /*
      input[type=range]::-moz-range-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #3071a9;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
      }

      input[type=range]::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
      }


      input[type=range]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }
      input[type=range]::-ms-fill-lower {
        background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type=range]::-ms-fill-upper {
        background: #3071a9;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type=range]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
      }
      input[type=range]:focus::-ms-fill-lower {
        background: #3071a9;
      }
      input[type=range]:focus::-ms-fill-upper {
        background: #367ebd;
      }
      */

        
}