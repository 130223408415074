.membership-wall {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  width: 23rem;
  padding: 0.167em 1.25em 0.167em 0.667em ;
  box-shadow: 5px 5px 0 0 #00000029;

  &--short {
    width: auto;
  }

  &--membership {
    background-color: var(--clr-success) !important;
    color: var(--clr-white) !important;
  }

  &--account {
    background-color: var(--clr-primary) !important;
    color: var(--clr-black) !important;
  }

  &:hover,
  &:focus {
    filter: brightness(1.05);

    .membership-wall__icon {
      display: none;

      &--hover {
        display: block;
      }
    }
  }

  &__icon {
    margin-right: 1em;
    display: block;

    &--hover {
      display: none;
    }
  }

  &__label {
    width: 100%;
    text-align: center;
  }
}
