.close-button {
    cursor: pointer;
    font-family: "Ebrima", arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0.125rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    border: none;
    letter-spacing: 0.25px;

    background-color: hsl(100deg, 38%, 56%);  // Green from the help agent
    color: hsl(100deg, 18%, 6%);
    filter: brightness(1);

    &--danger {
        background-color: #f24e4e;
        color: white;
    }

    &:hover,
    &:focus {
        filter: brightness(1.1);
    }
}
