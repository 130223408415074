.lesson-card {
  --height: 4.5rem;
  --padding: 0.5rem;
  $button-breakpoint: 450px;
  $level-breakpoint: 650px;

  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  align-items: center;

  @media (min-width: 350px) {
      grid-template-columns: 1fr max-content;
  }

  &__left {
    display: flex;
    align-items: center;
    padding-top: var(--padding);
    padding-bottom: var(--padding);
  }

  &__right {
    display: none;
    gap: 3rem;

    @media (min-width: $button-breakpoint) {
        display: grid;
    }

    @media (min-width: $level-breakpoint) {
        grid-template-columns: max-content 12rem;
    }

    .cl-button {
      text-align: center;
      padding-top: 0.75em;
      padding-bottom: 0.75em;
    }
  }

  &__image {
    --img-height: calc(var(--height) - var(--padding) * 2);

    position: relative;
    z-index: 1;
    height: var(--img-height);
    width: calc(var(--img-height) * 16 / 9);
    margin-right: 0.75rem;
    object-fit: cover;
  }

  &__title {
    color: var(--clr-black);
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 500;
  }

  &__level {
    display: none;
    z-index: 1;
    background-color: #636e72;
    color: var(--clr-white);
    padding: 0.25em;
    min-width: 7rem;
    text-align: center;
    border-radius: 100vw;
    text-transform: uppercase;
    height: fit-content;
    align-self: center;

    @media (min-width: $level-breakpoint) {
        display: block;
    }
  }
}
