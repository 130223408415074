@import 'assets/scss/common.scss';

.Leaderboard__wrapper {
    position:absolute;
    height:100%;
    width:100%;
    font-size:.3em;

    .canvas{
        position: absolute;
        top: 0;
        right:0;
        width: 100%;
        height: 100%;
        z-index:11;
        filter:drop-shadow(0 0 .25em yellow);
    }
    .list-title {
        position: absolute;
        margin-top:0em;
        color: #ffffff;
        width: 100%;
        margin-top:-.5em;
        top: 0;    
        text-transform: uppercase;
        justify-content: center;
        text-align:center;
    }

    .list-content {
        width: calc(100% - 1.5em);
        height: calc(100% - 2em);
        margin-left: .75em;
        margin-top: 1em;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

    &__item {
        height: 1.5em;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.38);
        background-color: #112c74;
        border-radius: 2px;        
        border: solid 3px #1d4cc8;
        margin-bottom: .3em;
        padding: .125em .5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.selected {
        box-shadow: 0 0 21px #00a3bf, inset 0 0 7px #00b3d2;
        border: 2px solid #75e3fb;
        background-color: #49c2d0;

        span {
            color: #ffffff !important;
        }
    }
    
  

        > span {
        color: white;
        font-size: .4em;
        font-weight: 700;
        }
        .details {
            color: white;
            flex: 1;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .8em;

            .name {
                color: white;
                font-weight: 400;
            }
            .value {
                color: white;
                font-weight: 700;
            }
        }
    }
    }
    .slick-slider {
        z-index: 10;
      
        .slick-prev {
          left: 0px;
        }
        .slick-next {
          right: 0px;
        }
        .slick-prev,
        .slick-next {
          width: 80px;
          height: calc(100% - 38px);
          background: rgba(0, 0, 0, 0.4);
          transition: 0.3s ease-in;
          z-index: 11;
      
          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }
      
          &::before {
            font-size: 30px;
          }
        }
    }
}
