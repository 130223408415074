.course-card {
  margin-top: 2rem;

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    margin-bottom: 0.75rem;
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #444f51;
  }

  &__coming-soon {
    background-color: var(--clr-primary);
    color: var(--clr-black);
    padding: 1rem;
  }

  &__icon-wrapper {
    position: relative;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.1);
  }

  &__description {
    margin: 0;
    margin-bottom: 2rem;
  }
}
