
.UME_SignIn_Form{
    position:relative;
    z-index:1001;
    .checkboxLayout{
        display:flex;
        position:relative;
        padding-top:.15em;
    }
    .checkbox{
        display:inline-block;
        position:relative;
        min-height:.75em;
        width:.75em;
        margin-top:.15em;
        margin-right:.15em;
    }
    label{
        position:relative;
    }
    .textButton{
        font-size:.35em;
        margin-left: .25em;
        line-height:1.5em;
    }
    .email__wrapper{
        margin-top:1rem;
        margin-bottom:1rem;
        padding: 1rem;
        padding-bottom: 0.5rem;
        font-size: 0.75rem;

        background: #0003;
        .small-title{
            text-align: center;
            font-size: 1rem;
            font-weight:700;
            margin-bottom:1.5rem;
        }

        .checklist li {
            padding-left: 0.325em;

            &::marker {
                content: "✓";
                color: var(--clr-success);
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
    .privacy{
        padding-top:.5rem;
        color:white;
        position:relative;
        text-align: right;
        width:100%;
        a{
            color: inherit;
        }
    }

    .buttonRow {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
}
