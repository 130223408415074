.MenuBar__wrapper{
    position:relative;
    z-index:100;
    height:1em;
    width:100%;
    background-color:white;
    .left{
        display:flex;
        position:absolute;
        left:0;
        pointer-events: none;
    }

    .centre{
        position:absolute;
        display:flex;
        left:50%;
        transform:translate(-50%,0);
    }

    .right{
        display:flex;
        flex-direction: row-reverse;
        position:absolute;        
        right:0;
    }

   
}