.cl-card {
  font-size: 1rem;
  min-height: 35ch;
  width: calc(18.5rem - 2px);
  background: var(--clr-white);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  outline: 2px solid var(--clr-black);

  &--loading {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--button {
    display: block;
    background: transparent;
    height: 100%;
    outline: none;

    & > button {
      width: 100%;
      height: 100%;
      background: transparent;
      border: 3px dashed currentcolor;
      color: var(--clr-black);
      border-radius: inherit;
      font-size: 1.3rem;
      font-family: var(--ff-body);
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: #fff4;
      }
    }

    .new-project-card__text {
      margin: 0 auto;
      max-width: 7.25rem;
    }

    .new-project-card__icon {
      font-size: 2.8rem;
    }
  }

  &--loading {
    --animation-delay: 0;
    --animation-duration: 2s;

    color: var(--clr-black);
    outline: none;

    @for $i from 0 to 8 {
      &.delay-#{$i} {
        --animation-delay: calc(calc(var(--animation-duration) / 8) * #{$i});
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fffa;
      animation-name: cl-pulse-border;
      animation-iteration-count: infinite;
      animation-duration: var(--animation-duration);
      animation-delay: var(--animation-delay);
      animation-timing-function: ease-in-out;
    }
  }

  &--sign-in {
    background-color: transparent;
    outline: none;

    @media (max-width: 619px) {
        min-height: auto;
    }

    .cl-card__info {
      display: flex;
      align-items: center;
    }

    .cl-card__title {
      font-weight: 400;
      padding-top: 0;
    }
  }

  &__image {
    width: 100%;
    height: 10.75rem;
    display: block;
    object-fit: cover;
    position: relative;

    &--contain {
      object-fit: contain;
    }

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #aaa;
      content: "";
    }
  }

  &__info {
    flex-grow: 1;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__date {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #000d;
    grid-area: date;
    align-self: flex-start;
  }

  &__title {
    color: var(--clr-black);
    font-size: 1.3rem;
    margin: 0;
    font-weight: 500;
    height: 3.5em;
    padding-top: 1.3rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__author {
    font-size: 0.875rem;
    padding-left: 1.25rem;
    padding-bottom: 0.75rem;
    display: grid;
    grid-template-areas:
      'image username'
      'image date'
    ;
    grid-template-columns: max-content max-content;
    width: min-content;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__avatar {
    grid-area: image;
  }

  &__username {
    grid-area: username;
    align-self: flex-end;
  }
}

.portrait {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: var(--clr-accent);
  overflow: hidden;

  &__image {
    transform: scale(1.1) translateY(5%);
  }
}

@for $i from 0 to 30 {
  .fade-in-#{$i} {
    animation: 1s #{$i * 0.05s} both fadeIn;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes cl-pulse-border {
  0%,
  60%,
  100%{
    opacity: 0.8;
  }

  15% {
    opacity: 0;
  }
}
