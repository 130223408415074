@import 'assets/scss/common.scss';

.Modal_pg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96%;
    canvas{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;  
        z-index:1000;
        pointer-events: none;
    }&:after {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - .3em);
        height: calc(100% - .3em);
        border: solid .075em rgb(75, 168, 255);
        border-radius: .075em;
        margin: .075em;
        filter: blur(0.05em); 
        


      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - .2em);
        height: calc(100% - .2em);
        border: solid .05em rgb(125, 192, 255);
        border-radius: .1em;
        margin: .05em;
        filter: blur(0.01em);       
      }
    .slick-slider{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      margin-left:0;
    }
    .content{

    }
    
    .close_wrapper_pg {
      position:absolute;
      z-index:1002;
      top:.25em;
      right:.25em;
      width:.5em;
      height:.5em;
      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //background-color: #0000008c;
        
        cursor: pointer;
    
        
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 60%;
          height: .02em;
          transform: translate(-50%, -50%) rotate(45deg);
          transform-origin: center center;
          background: #ffffe6;
          box-shadow:0 0 .03em .01em #ffd900
          
        }
        &:hover:before {
            box-shadow:0 0 .03em .01em #00a3bf
          }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 60%;
          height: .02em;
          transform: translate(-50%, -50%) rotate(-45deg);
          transform-origin: center center;
          background: #ffffe6;
          box-shadow:0 0 .03em .01em #ffd900
        }
        &:hover:after {
            box-shadow:0 0 .03em .01em #00a3bf
          }
      
      }
    }
}
.Modal.fullScreen{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
}