@mixin screen_xl {
  @media screen and (min-width: 1721px) and (orientation: landscape) {
    @content;
  }
}

@mixin screen_lg {
  @media screen and (max-width: 1720px) and (min-width: 1291px) {
    @content;
  }
}

@mixin screen_md {
  @media screen and (max-width: 1290px) and (min-width: 861px) {
    @content;
  }
}

@mixin screen_sm {
  @media screen and (max-width: 860px) and (min-width: 481px) {
    @content;
  }
}

@mixin screen_xs {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin mobile_portrait {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin mobile_landscape {
  @media screen and (max-height: 480px) {
    @content;
  }
}

@mixin laptop_short {
  @media screen and (max-height: 768px) and (min-height: 500px) {
    @content;
  }
}

@mixin large_screen {
  @media screen and (min-width: 1366px) and (orientation: landscape) {
    @content;
  }
}
@mixin small_screen {
  @media screen and (min-width: 1366px) and (orientation: landscape) {
    @content;
  }
}
