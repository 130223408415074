
.mainContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-size: .7em;
        color: white;
    }

    .explanation {
        font-size: .4em;
        color: white;
        margin: .3em 0;
    }
}