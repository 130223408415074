@import "./animations";
@import "./mixins";
@import "./variables";
@import "./text";

.slick-slider {
  .slick-list {
    width: 100%;
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide {
        position: relative;
      }
    }
  }
}
canvas {
  pointer-events: none;
}

.neonBorder { 
  //border-radius: .15em;
  transition: 0.3s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  box-shadow: inset 0 0 40px 80px rgba(0, 0, 0, 0.2);
  //border-radius: .15em;

  &:hover {
    animation: neonBorder 1.25s ease-in-out infinite alternate;
  }
  &.selected {
    animation: neonBorder 1.25s ease-in-out infinite alternate;
  }
}

.neonBorderProminent { 
  //border-radius: .15em;
  transition: 0.3s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  box-shadow: inset 0 0 40px 80px rgba(0, 0, 0, 0.2);
  //border-radius: .15em;

  &:hover {
    animation: neonBorderProminent 1.25s ease-in-out infinite alternate;
  }
  &.selected {
    animation: neonBorderProminent 1.25s ease-in-out infinite alternate;
  }
}

.greenBorder {
  animation: greenBorder 0.5s ease-in-out infinite alternate;
  &:hover {
    animation: greenBorder 1.25s ease-in-out infinite alternate;
  }
  &.selected {
    animation: greenBorder 1.25s ease-in-out infinite alternate;
  }
}
.redBorder {
  animation: redBorder 0.5s ease-in-out infinite alternate;
  &:hover {
    animation: redBorder 1.25s ease-in-out infinite alternate;
  }
  &.selected {
    animation: redBorder 1.25s ease-in-out infinite alternate;
  }
}
.neonBorderDouble {
  border-radius: 8px;
  transition: 0.3s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;

  &:hover {
    -webkit-animation: neonBorderDouble 1.25s ease-in-out infinite alternate;
    -moz-animation: neonBorderDouble 1.25s ease-in-out infinite alternate;
    animation: neonBorderDouble 1.25s ease-in-out infinite alternate;
    //transform: scale(1.05) rotateY(24deg) rotateZ(3deg) rotateX(15deg);
    //transition: .1s ease-in-out;
  }

  &.has-opacity {
    opacity: 0.6;
  }

  &.selected {
    -webkit-animation: neonBorderDouble 1.25s ease-in-out infinite alternate;
    -moz-animation: neonBorderDouble 1.25s ease-in-out infinite alternate;
    animation: neonBorderDouble 1.25s ease-in-out infinite alternate;

    z-index: 11;
    opacity: 1;

    &::after {
      display: none;
      content: "";
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      //border-top: 15px solid #b8effb;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
    }
  }
}

.textGlow_yellow {
  color: #fff09e;
  text-shadow: 0.03em 0.03em 0.1em #ffd90088, 0.03em -0.03em 0.1em #ffd90088, -0.03em 0.03em 0.1em #ffd90088,
    -0.03em -0.03em 0.1em #ffd90088;
}
.textGlow_yellowBold {
  color: #fff09e;
  font-weight: 700;
  text-shadow: 0.03em 0.03em 0.1em #ffd90088, 0.03em -0.03em 0.1em #ffd90088, -0.03em 0.03em 0.1em #ffd90088,
    -0.03em -0.03em 0.1em #ffd90088, 0.03em 0.01em 0.35em #ffd90059, 0.03em -0.01em 0.35em #ffd90059,
    -0.03em 0.01em 0.35em #ffd90059, -0.03em -0.01em 0.35em #ffd90059;
}
.textGlow_purple {
  color: #ff1efb91;
  text-shadow: 0.03em 0.03em 0.1em #c5217f, 0.03em -0.03em 0.1em #c5217f, -0.03em 0.03em 0.1em #c5217f,
    -0.03em -0.03em 0.1em #c5217f;
  &:hover {
    text-shadow: 0 0 0.03em #161a2a, inset 0 0 0.03em #161a2a, 0 0 0.05em #0090ff, inset 0 0 0.05em #0090ff,
      0 0 0.03em white, inset 0 0 0.03em white;
    color: #b8effb;
  }
}

.textGlow_purpleBold {
  color: #ffddef;
  font-weight: 700;
  text-shadow: 0.03em 0.03em 0.1em #ff1efb91, 0.03em -0.03em 0.1em #ff1efb91, -0.03em 0.03em 0.1em #ff1efb91,
    -0.03em -0.03em 0.1em #ff1efb91, 0.03em 0.01em 0.75em #c5217f, 0.03em -0.01em 0.75em #c5217f,
    -0.03em 0.01em 0.75em #c5217f, -0.03em -0.01em 0.75em #c5217f;
}
.glow_purple {
  color: #ff1efb91;
  text-shadow: 0.03em 0.03em 0.075em #ff1efb91, 0.03em -0.03em 0.075em #ff1efb91, -0.03em 0.03em 0.075em #ff1efb91,
    -0.03em -0.03em 0.075em #ff1efb91, 0.03em 0.01em 0.25em #c5217f, 0.03em -0.01em 0.25em #c5217f,
    -0.03em 0.01em 0.25em #c5217f, -0.03em -0.01em 0.25em #c5217f;
  box-shadow: 0 0 0.3em #c5217f, inset 0 0 0.3em #c5217f, 0 0 0.1em #ff1efb91, inset 0 0 0.1em #ff1efb91,
    0 0 0.074em white, inset 0 0 0.075em white;
  border: solid #ff1efb91;
  &:hover {
    color: #ffddef;
  }
}

input {
  display: block;
  width: 100%;
  height: 100%;
  font-family: Ebrima;
  padding: 0.33em 1em 0.33em 0.25em;
  border: solid #32343b 0.3em;
  background-color: white;
  border-radius: 0.3em;
  color: black;
  &::placeholder {
    color: grey;
  }
}
input:-internal-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #32343b !important;
}
