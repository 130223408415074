.ToolMenu__wrapper{
    height:1em;
    width:1em;    
    color: white;

    .hamburger{
        font-size: .9em;
        line-height: 1em;
        text-align: center;
        color: grey;
    }&:hover{
        background:grey;
        color:white;
    }
    .ToolMenu__layout{
        display:none;
    }
    &:hover{
        .ToolMenu__layout{
            display:block;
            position:fixed;
            right:0;
            width:20%;
            height:10em;
            background-color:rgba(.6,.6,.6,.6);
        }
    }
}