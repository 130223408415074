@import 'assets/scss/common.scss';

.MicIndicator__wrapper {
    width: 100%;
    height: 100%;

    .mic {
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 100%;

        &.broken {
            background-image:url(./images/mic-broken.png);
        }

        &.muted-enabled {
            background-image:url(./images/mic-muted-enabled.png);
        }

        &.muted-disabled {
            background-image:url(./images/mic-muted-disabled.png);
        }

        &.unmuted-enabled {
            background-image:url(./images/mic-unmuted-enabled.png);
        }

        &.unmuted-disabled {
            background-image:url(./images/mic-unmuted-disabled.png);
        }

    }

}