@import 'assets/scss/common.scss';

.Rate__wrapper{
    position:absolute;
    top:0;
    width:100%;
    height:100%;
    background-color:grey;
    z-index:1001;

    .centered{
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: .1em;
       
        text-align:center;
        .title{
            font-size:.1em;
        }
        .rating__wrapper{
            font-size:.25em;
            display:flex;
            justify-content: center;
            vertical-align: middle;
            .label{
                width:30%;
            }
            .rating{
                width:50%;
            }
        }
        .button{
            width:min-content;
        }
    }
}