@import 'assets/scss/common.scss';

.ContextMenu__wrapper{
  position:fixed;
  display:none;
  z-index:1000;
  min-width:4em;
  //max-width:75vw;
  //max-height:75vh;
  min-height:min-content;
  background-color:whitesmoke;
  border-radius: .15em;
  border:.1em solid transparent;
  color:#294257;
  filter: drop-shadow(2px 2px 4px #00000031);
  
  .actions{
    position:absolute;
    z-index:1002;
    top:.15em;
    right:.15em;
    width:.6em;
    height:.6em;
    text-align: center;
    //margin-top: 0;
    //margin: 5% auto 50px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;    
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: .02em;
        transform: translate(-50%, -50%) rotate(45deg);
        transform-origin: center center;
        background: #ffffe6;
        box-shadow:0 0 .03em .01em #ffd900          
      }
      &:hover:before {
          box-shadow:0 0 .03em .01em #00a3bf
        }
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: .02em;
        transform: translate(-50%, -50%) rotate(-45deg);
        transform-origin: center center;
        background: #ffffe6;
        box-shadow:0 0 .03em .01em #ffd900
      }
      &:hover:after {
          box-shadow:0 0 .03em .01em #00a3bf
        }
    
    }
  }
  .canvas{
    position: absolute;
    top: 0;
    right:0;
    width: 100%;
    height: 100%;
    z-index:11;
    filter:drop-shadow(0 0 .05em yellow);
    
    }
    .title{
        font-size:.6em;
        text-transform: uppercase;
        justify-content: center;
        display:flex;
        width:100%;
        height:min-content;
        margin-bottom:.25em;
      }
      .title_message{
        font-size:.4em;
        color:white;
        margin-bottom:.25em;
      }
  .Schedule__wrapper{
    border:.25em solid transparent;
    .ScheduleSlider__wrapper{
        .label{
        font-size:.4em;
        font-weight:700;
        }
    }
  }
  .buttonRow{
    position:fixed;
    bottom:.25em;
    margin-top:.5em;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
.ContextMenu__wrapper.show{
    display:block;
}