@import 'assets/scss/common.scss';
.Button__wrapper{
    position:relative;
    text-align: center;
    margin: 0 auto;
    margin-top:.75em;
    margin-bottom:.75em;
    display:flex;
    justify-content: center;
    .button{
        position:initial;
        display:inline-block;
        width:max-content;
        min-width:5em;
        padding-left:.3em;
        padding-right:.3em;
        text-align: center;
        justify-content: center;
        min-height:1.5em;
        margin-top:.15em;
        margin-bottom:.15em;
        margin-left:.15em;
        margin-right:.15em;
        font-family: 'Ebrima';
        font-size: .5em;
        text-transform: uppercase;
        border-radius: .15em;
        background-color:rgba(0,0,0,0);
        cursor: pointer;    
        &.disabled{
            display:none;
        }    
    }
    .button.simple{
        background-color:grey;
        border:transparent;
        color:white;
        text-shadow:none;
        box-shadow:none;
    }
}
.Button__wrapper.right{
    justify-content: flex-end;
}
.Button__wrapper.left{
    justify-content: flex-start;
}


        
