.share-signup {
  &__tagline {
    margin: 0;
    margin-bottom: 0.5em;
    text-align: center;
    color: var(--clr-white);
    font-weight: 500;
  }
}

.share-badge {
  max-width: 23rem;

  &__header {
    display: grid;
    grid-template-areas:
      'avatar title'
      'avatar username'
    ;
    grid-template-columns: 112px 1fr;
    grid-template-rows: 1fr max-content;
  }

  &__avatar-wrapper {
    position: relative;
    grid-area: avatar;
  }

  &__avatar {
    position: absolute;
    bottom: 0;
  }

  &__title {
    grid-template: title;
    align-self: flex-end;
    margin: 0;
  }

  &__username {
    grid-template: username;
    margin: 0;
    align-self: flex-start;
  }

  &__body {
    position: relative;
    z-index: 0;
    margin: 0;
    margin-top: 0.5rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;

    &::after {
      position: absolute;
      top: 0;
      left: calc(-0.625rem + 4px);
      right: calc(-0.625rem + 4px);
      bottom: 0;
      background: #00000020;
      content: "";
      z-index: -1;
      mix-blend-mode: darken;
    }
  }
}
