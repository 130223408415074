.creator-lab {
  &__header {
    display: grid;
    grid-template-areas: 'title' 'description' 'image';
    grid-template-rows: min-content 1fr;
    column-gap: 2rem;
    max-width: 80rem;
    min-height: 18rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 900px) {
        grid-template-areas:
          'title image'
          'description image'
        ;
    }
  }

  &__title {
    padding: 1.75rem 0;
    padding-top: 3.5rem;
    margin: 0;
    color: var(--clr-white);
    font-size: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__tagline {
    color: var(--clr-white);
    font-size: 1.2rem;
    line-height: 1.7;
    margin: 0;
    max-width: 30ch;

    strong {
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__image {
    grid-area: image;
    align-self: flex-end;
    margin-top: 2rem;
  }
}

.noscroll {
  overflow: hidden;
  height: 100%;
}

.link {
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.section--creator-lab {
  max-width: 80rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: auto;
  margin-right: auto;

  .section__body {
      min-height: 70rem;
  }
}
