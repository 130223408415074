.lesson-banner {
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  padding: 1.5rem 0.5rem;
  margin: 0 auto;
  color: var(--clr-white);
  gap: 1rem;
  grid-area: banner;

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -50vw;
    width: 200vw;
    height: 100%;
    background: #383838;
    content: "";
  }

  p {
    margin: 0;
  }

  &__thumbnail {
    display: none;
    
    @media (min-width: 850px) {
      display: block;
    }
  }

  &__content {
    display: grid;
    flex-grow: 1;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__title {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__summary {
    padding-bottom: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .membership-container {
      margin-top: 1rem;
      align-self: flex-end;
      flex-grow: 1;
      max-width: 23rem;

      a {
        width: auto;
      }
    }
  }

  &__outcomes {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span[role="img"] {
      margin-right: 0.25rem;
    }
  }

  &__button {
    font-family: var(--ff-body);
    text-transform: uppercase;
  }
}
