.game-frame {
  z-index: 1;
  width: 100%;
  height: min(calc(100vw * 9 / 16), 75vh); /* 16/9 aspect ratio with width, but no more than 75% of the vertical height (so that info doesn't drop below the fold) */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.unity-iframe,
.game-cover {
  position: absolute;
  border: none;
  background-size: contain;
}

.unity-iframe {
  opacity: 1;
  transition: opacity 1000ms ease-in;

  &--hidden {
    opacity: 0;
  }
}

#gameContainer {
  box-shadow: 0 0 0 2px;
}

.webgl-content {
  background-size: contain;
}
