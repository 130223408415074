.arcade__panel {
  margin-top: 2rem;
  margin-left: var(--body-padding);
  height: 3.375rem;
  display: flex;
  align-items: center;
}

.arcade__title {
  color: var(--clr-white);
  font-weight: 500;
  text-transform: uppercase;
}

.arcade__games-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1.5rem var(--body-padding);
}
