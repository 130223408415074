.home {
  font-size: 1rem;
  background-color: #39575a;
}

.home-top-bar {
  font-size: 0.75rem;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #fbfbfb;
  text-align: center;
  font-family: Rubik, arial, sans-serif;

  a {
    color: inherit;
  }
}

.home-card-section {
  background-color: #6d9da2;
  padding-top: 4.75rem;
  padding-bottom: 1.5rem;

  &__title {
    margin: 0;
    margin-bottom: 4.75rem;
    font-weight: 500;
    font-size: 1.75rem;
    color: var(--clr-white);
    text-align: center;
    text-transform: uppercase;
  }

  &__container {
    display: grid;
    column-gap: 5rem;
    max-width: min(68rem, 95vw);
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.home-kid-safe-section {
  display: grid;
  grid-template-areas:
    'image title'
    'image body'
  ;
  grid-template-rows: min-content 1fr;
  row-gap: 1.75rem;
  column-gap: 1.75rem;
  max-width: 50rem;
  margin: auto;
  padding-top: 5.5rem;
  padding-bottom: 4rem;

  &__image {
    grid-area: image;
    max-width: 25vw;
  }

  &__title {
    margin: 0;
    margin-top: 0.25rem;
    color: var(--clr-white);
    font-size: 1.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__body {
    margin: 0;
    color: var(--clr-white);
    line-height: 1.5;
  }
}
