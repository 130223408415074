@import 'assets/scss/common.scss';

.skills__wrapper{
    text-align: center;
    color:white;
    .title{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        text-transform: uppercase;
        font-size:.3em;
        color:white;
    }
    .details{

    }
    .skill-item{
        display:flex;
        float:left;
        height:100%;
        justify-content: center;
        margin-top: .4em;
        text-transform: uppercase;
        width:calc(33.33%);
        
    }
    img{
        width:100%;
        height:100%;
        object-fit:contain;
        object-position: bottom;
    }
    span{
        position:absolute;
        bottom:0;
        margin:auto 0;
        font-size:.18em;
    }
}