.home-card {
  color: white;
  display: grid;
  grid-template-areas:
    'image title'
    'image body'
  ;
  grid-template-rows: max-content 1fr;
  column-gap: 1rem;
  text-align: left;

  @media (min-width: 1000px) {
      display: block;
      text-align: center;
  }

  &__image {
    margin-left: auto;
    margin-right: auto;
    grid-area: image;
    max-width: 25vw;
    object-fit: scale-down;
  }

  &__title {
    margin: 0;
    margin-top: 2.75rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 500;
  }

  &__body {
    line-height: 1.5;

    strong {
      font-weight: 400;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      margin-top: 2rem;
    }

    p:not(first-child) {
      margin-top: 1.75rem;
    }
  }
}
