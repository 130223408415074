.arcade-category-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  background-color: var(--clr-primary);
  font-size: 1.125rem;
  z-index: 10;
  cursor: pointer;
  width: 19ch;
  color: var(--clr-black);
  transition: filter 150ms ease-in-out;

  &:hover {
    filter: brightness(1.1);
  }

  select {
    appearance: none;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    cursor: inherit;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    padding-right: 2.2em;
    padding-left: 1.2em;
    width: 100%;
    grid-area: select;
    color: inherit;
  }

  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    margin-right: 0.7em;
    background-color: currentcolor;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end;
  }
}
