.card-grid {
  $min-width: 360px;
  $margin: 0;
  $gap: 16px;

  display: flex;
  flex-wrap: wrap;
  /* display: grid;
     grid-template-columns: repeat(auto-fit, minmax(#{$min-width}, 1fr)); */
  padding: 0;
  margin: $margin;
  gap: $gap;

  &__item {
    display: block;
    list-style-type: none;

    &--filler {
      @for $i from 0 to 6 {
        // 1407px is the max the first column can be before an extra column is
        // added
        @media (max-width: #{1407 + ($margin * 2) + $i * ($min-width + $gap)}) {
          &:nth-child(#{2 + $i}) {
            display: none;
          }
        }
      }
    }
  }
}
