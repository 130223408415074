
.Game__wrapper{
    position:absolute;
    height:100%;
    width:100%;
    display:none;
    //padding-bottom:56%;
    margin: 0 auto;
    background-color:#0a324d;
    &.show{
      display:block;
    }
  
  }