@import "assets/scss/common.scss";

.Screen__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &.border {
    border: solid 0.175em #0a324d;
  }
  &.disableControl {
    cursor: not-allowed;
  }
  .slick-slider {
    height: 100%;

    .slick-active {
      z-index: 1;
    }
  }
  .screen_frame {
    position: absolute;
    width: 100%;
    height: 100%;

    &.lobbyview {
      background-color: #624177;
    }
    &.gridview {
      background-color: #0a324d;
      pointer-events: none;
    }
    &.watch {
      pointer-events: none;
    }
    &.create {
      pointer-events: none;
    }
    &.control {
      pointer-events: unset !important;
    }

    &.portfolioView {
      overflow: hidden;

      .slick-track {
        height: unset !important;
      }
    }
  }
  iframe {
    /*
    position:absolute;
    width:calc(100%);
    height:calc(100%);
    */
    border: 0px;
    border-radius: 0px;
  }
}
