.GridViewItem__wrapper {
  position: relative;
  width: 100%;
  border: solid transparent 0.5vw;
  // Note: Padding drives the gridview off the screen when going fullscreen (F11)
  // Old padding kept below for reference but it's styling was negligible 
  // padding-top: 0.25vw;
  // padding-bottom: calc(46% + 0.5125em);
  margin: 0 auto;

  &.viewScreen {
    position: fixed;
    width: 100%;
    //width: calc(100vw - 0.7em);
    //height: 100vh;
    margin: unset;
    top: 0;
    left: 0;
    border: unset;
    padding: 0;
    z-index: 1;
    .topContent {
      display: None;
    }
    .container {
      border: unset;
      border-radius: 0;
      padding-bottom: calc(48% - 2 * 0.3em);
    }
  }
  .arm {
    position: absolute;
    display: none;
    top: 0;
    right: -0.5em;
    width: 3em;
    height: 5em;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url(./images/avatar_arm.png);
    &.show {
      animation: wave 0.75s ease-in-out infinite alternate;
      display: block;
    }
  }
  .topContent {
    position: relative;
    width: 100%;
    float: left;
    height: 0.5em;
    margin-bottom: 0.125em;
    margin-left: 0.125em;
    .label {
      position: absolute;
      //width:100%;
      bottom: 0;
      left: 0;
      justify-content: center;
      font-size: 0.35em;
      color: white;
      text-shadow: 0 0 0.1em;
      text-align: left;
      vertical-align: center;
    }

    .volumeLevel {
      position: relative;
      float: right;
      left: 0;
      width: 0.6em;
      height: 0.6em;
    }
    .connection {
      position: relative;
      left: 0.075em;
      float: right;
      width: 0.45em;
      height: 0.45em;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.poor {
        background-image: url(./images/wifi_icon.png);
      }
    }
  }
  .container {
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: calc(46% - 0.125em);
    background-color: green;
    cursor: pointer;
    border: solid 0.125em pink;
    border-radius: 0.25em;
    .contextMenu {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1; // overide the iframe for clicks
      &.viewScreen {
        display: none;
      }
    }

    .streamed_dcv {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      .scaleX {
        transform-origin: left;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .scaleY {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-origin: top;
        iframe {
        }
      }
    }
  }
}
