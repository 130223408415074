.home-header {
  position: relative;
  background-image: url('../../assets/landingpage-header.png');
  background-size: cover;
  background-position: center;

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(
      circle at center,
      rgba(255 255 255 / 0.6) 0%,
      transparent 20rem
    );
    padding-top: 4.5rem;
    padding-bottom: 8rem;
  }

  &__logo {
    display: block;
  }
}

.home-guest {
  background: rgba(0 0 0 / 0.45);
  color: white;
  text-align: center;
  padding-top: 3.25rem;
  padding-bottom: 4rem;

  &__tagline {
    font-size: 1.75rem;
    margin-top: 0;
    margin-bottom: 3.5rem;

    em {
      font-style: normal;
      text-transform: uppercase;
    }
  }

  &__button {
    display: block;
    color: white;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &--primary {
      font-size: 1.125rem;
      text-decoration: none;
      margin-bottom: 1.5rem;
      padding: 1rem 5rem;
      background-color: #a85aa3;
      text-transform: uppercase;
      box-shadow:
        inset 2px 2px 2px rgb(255 255 255 / 0.5),
        3px 3px 0px #00000029;
      ;
      transition: filter 150ms ease-in-out;

      &:hover,
      &:focus {
        filter: brightness(1.1);
      }
    }
  }
}

.home-characters {
  position: absolute;
  left: calc(50% + 10rem);
  bottom: 0;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  &__image {
    display: block;
  }

  &__speech-bubble {
    position: relative;
    background: var(--clr-white);
    padding: 0.8rem 2rem;
    border-radius: 1rem;
    max-width: 16rem;
    margin-left: 6rem;
    margin-bottom: 3rem;
    line-height: 1.5;
    text-align: center;

    &::after {
      --height: 30px;
      --width: 15px;

      position: absolute;
      left: 15%;
      top: 100%;
      border-left: var(--width) solid transparent;
      border-right: var(--width) solid transparent;
      border-top: var(--height) solid var(--clr-white);
      content: "";
    }
  }
}
