@import 'assets/scss/common.scss';

.HelpAgentIcon__wrapper {
    width: 100%;

    .help-agent-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.closed {
            background-image: url("./images/help_agent_closed.png");
        }

        &.idle-1 {
            background-image: url("./images/help_agent_idle_00.png");
        }

        &.idle-2 {
            background-image: url("./images/help_agent_idle_02.png");
        }
    }

    .speech-bubble {
        position: absolute;
        width: 6em;
        bottom: 0;
        right: 1.6em;
        height: auto;
        background-color: white;
        border-radius: 0.3em;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &:after {
            content: '';
            position: absolute;
            left: auto;
            right: -0.4em;
            top: auto;
            bottom: 10px;
            border-left: 10px solid white;
            border-right: 10px solid transparent;
            border-top: 8px solid white;
            border-bottom: 8px solid transparent;
            border-color: white transparent transparent white;
        }

        &.closed {
            display: none;
        }

        &.open {
            display: block;
        }

        .ml-help-content-div {
            display: flex;
            flex-direction: column;
            padding: 0.4em;
            max-height: 90vh;

            .actions {
                position: absolute;
                z-index: 1002;
                top: .05em;
                right: .15em;
                width: .6em;
                height: .6em;
                text-align: center;

                //margin-top: 0;
                //margin: 5% auto 50px;
                .close {
                    position: absolute;
                    right: 0.1em;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60%;
                        height: .02em;
                        transform: translate(-50%, -50%) rotate(45deg);
                        transform-origin: center center;
                        background: #ffffe6;
                        box-shadow: 0 0 .03em .01em #080808;
                    }

                    &:hover:before {
                        box-shadow: 0 0 .03em .01em #00a3bf;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60%;
                        height: .02em;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        transform-origin: center center;
                        background: #ffffe6;
                        box-shadow: 0 0 .03em .01em #0c0c0c;
                    }

                    &:hover:after {
                        box-shadow: 0 0 .03em .01em #00a3bf;
                    }

                }
            }

            .talktext {
                text-align: justify;
                font-size: 0.4em;
                margin: 0 0 1em;
            }

            .helpImage {
                text-align: center;
            }

            .buttonRow {
                display: flex;
                justify-content: space-evenly;
                flex-direction: row;
                flex-wrap: nowrap;
                row-gap: 0.25em;

                .Button__wrapper {
                    margin: 0;

                    .button {
                        font-size: 0.3em;
                        background-color: white;
                        color: black;
                        text-shadow: none;
                        box-shadow: none;
                        text-align: center;
                    }
                }
            }
        }

    }
}