.tooltip {
  position: relative;
  display: inline-block;

  &__target {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25em;
    margin-left: 0.5rem;
    border: 1px solid var(--clr-black);
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    font-weight: 500;
    cursor: default;
    background-color: var(--clr-white);
  }

  &__tip {
    position: absolute;
    display: none;
    padding: 0.75rem;
    margin-top: 0.25rem;
    background-color: var(--clr-primary);
    width: 100vw;
    max-width: 30ch;
    box-shadow: 0 4px 8px #0004;
    font-size: 1rem;

    &::before {
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 100%;
      height: 0.25rem;
      content: "";
    }

    p {
      margin: 0;

      & + p {
        margin-top: 0.75em;
      }
    }
  }

  &:hover {
    .tooltip__tip {
      display: block;
    }
  }
}
