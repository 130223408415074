@import 'assets/scss/common.scss';

.AudioIndicator__wrapper {
    width: 100%;
    height: 100%;

    .audio {
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 100%;

        &.broken {
            background-image:url(./images/mic_icon.png);
        }

        &.muted {
            background-image:url(./images/audio_muted.png);
        }

        &.off {
            background-image:url(./images/audio_on_00.png);
        }

        &.low {
            background-image:url(./images/audio_on_01.png);
        }

        &.mid {
            background-image:url(./images/audio_on_01.png);
        }

        &.high {
            background-image:url(./images/audio_on_03.png);
        }

    }

}