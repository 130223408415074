
.Welcome__wrapper{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border: solid transparent .5vw;
    padding-top:.25vw;
    //padding-bottom:56%;
    margin: 0 auto;
    background-color:#0a324d;
    text-align:center;
    color:white;
   .Timer__wrapper{
     position:relative;
     left:50%;
     transform:translateX(-50%);
   }
   .raisearm_tip{
    position: absolute;
    width: 4em;
    height: 4em;
    margin-left:-1em;
    background-image:url(images/raisearm_anim.gif);
    background-size:contain;
    background-repeat:no-repeat;
    bottom: 0;   
    &.hide{
      display:none;
    }
   }
  }