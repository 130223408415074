.Rundown__wrapper{
    width: 5.5em;
    height: 1em;
    display: inline-flex;

    &--narrow {
        width: auto;
    }

    .message__wrapper {
        width: 2.5em;
        height: 1em;
        display: inline-block;

        .text {
            width: inherit;
            height: inherit;
            font-size: .3em;
            font-weight: 600;
            line-height: 1em;
            //position: absolute;
            //top: 0.75em;
            text-align: center;

            &.starting {
                color: #00AAC7;
            }
        }

        &.warning {
            color: #F24E4E;
            border: .05em solid #F24E4E;
            padding-top: 0.175em;
        }

        &.starting {
            color: #00AAC7;
            border: .05em solid #00AAC7;
            padding-top: 0.175em;
        }

        &.regular {
            visibility: hidden;
        }
    }


    .timer__wrapper {
        width: 3.5em;
        height: 1em;
        display: inline-block;
        padding-left: .25em;

        .time {
            font-size: .4em;
            //line-height: 2.5em;
            font-weight: 600;
            position: absolute;
            top: 0.5em;
            right: 3em;

            &.starting {
                color: white;
            }

            &.warning {
                color: white;
            }

            &.regular {
                color: grey;
            }
        }

        .icon {
            // float: left;
            margin: 0;
            position: absolute;
            width:.5em;
            height:.5em;
            top: 50%;
            //left: .5em;
            //right: 3.5em;
            //left:0em;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            background-size:contain;

            &.starting {
                background-image:url(./images/ClockWhite.png);        
            }

            &.warning {
                background-image:url(./images/ClockWhite.png);        
            }

            &.regular {
                background-image:url(./images/ClockGrey.png);        
            }
        }

        &.starting {
            background-color: #FFD753;
        }

        &.warning {
            background-color: #F24E4E;
        }

        &.regular {
            background-color: white;
        }
        
    }

}