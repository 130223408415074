.badge-wrapper {
  --border-radius: 3rem;
  --border-thickness: 4px;
  --border-clr: var(--clr-black);

  position: relative;
  z-index: 1;
  display: grid;
  margin-top: 2rem;
  gap: 1.5rem;
  grid-template-rows: 1fr min-content;

  &--owned {
    --border-clr: var(--clr-success);
  }

  &--org {
    width: 100%;
    max-width: calc(2 * 23rem + 1rem);
    margin-left: auto;
    margin-right: auto;
  }

  &__button-row {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: center;
  }

  &--subscription .badge-wrapper {
    &__button-row {
      height: 3.375rem;
    }

    &__owned-info {
      display: grid;
    }

    &__info-text {
      font-size: 1.125rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0.9em;
      padding-bottom: 0.25em;
    }
  }

  &__button {
    width: 60%;
    text-align: center;

    &--accent {
      background-color: var(--clr-success);
      color: var(--clr-white);
    }

    &--constrained {
      max-width: calc(23rem * 0.75);
    }
  }

  &__link {
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    color: var(--clr-white);
    cursor: pointer;
    font-family: var(--ff-body);
    text-decoration: underline;
  }
}

.membership-card {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 23rem;
  padding: 0.625rem;
  border-radius: var(--border-radius);
  color: var(--clr-black);
  font-size: 1.125rem;

  &--no-footer {
    padding-bottom: 3rem;
  }

  &--plan {
    min-height: 25rem;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: var(--border-thickness) solid var(--border-clr);
    background: var(--clr-white);
    border-radius: inherit;
    content: "";
    -webkit-mask-composite: exclude;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    mask-composite: xor;
    -webkit-mask-image:
      -webkit-linear-gradient(black, black),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="72" viewBox="0 0 2 3"><rect width="2" height="3" rx="1" /></svg>');
    mask-image:
      linear-gradient(black, black),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="72" viewBox="0 0 2 3"><rect width="2" height="3" rx="1" /></svg>');
    -webkit-mask-position:
      top left,
      center -36px;
    mask-position:
      top left,
      center -36px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size:
      100% 100%,
      48px 72px;
    mask-size:
      100% 100%,
      48px 72px;
  }

  &::after {
    position: absolute;
    top: -36px;
    left: 50%;
    width: 48px;
    height: 72px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="72" fill="none" viewBox="0 0 2 3"><rect width="2" height="3" rx="1" /></svg>');
    border-radius: 100vw;
    box-shadow: 0 0 0 var(--border-thickness) var(--border-clr);
    color: var(--clr-black);
    content: "";
    transform: translateX(-50%) scale(0.95);
  }

  &__pill {
    position: absolute;
    top: -36px;
    left: 50%;
    color: var(--border-clr);
    transform: translateX(-50%) scale(0.8);
  }

  &__background {
    position: absolute;
    z-index: -1;
    top: 6rem;
    left: 50%;
    width: 100%;
    max-height: calc(100% - 6rem);
    object-fit: contain;
    transform: translateX(-50%);
  }

  &__title {
    margin: 3rem 0 1rem 0;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  &__details {
    display: grid;
    flex-grow: 1;
    padding: 0;
    margin: 1rem 0;
    padding-left: 2rem;
    gap: 0.75em;
    grid-auto-rows: min-content;
    list-style-type: none;

    &--centered {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__detail-item {
    padding-left: 0.5em;

    &::marker {
      content: "⭐";
    }
  }

  &__promo {
    --flag-clr: var(--clr-success);

    width: fit-content;
    margin: auto;
    margin-bottom: 1.5rem;
    color: var(--clr-white);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.875rem;
    filter: drop-shadow(1px 2px 0 #0002);
  }

  &__footer {
    display: flex;
    height: 5rem;
    align-items: center;
    background-color: #e8e8e8;
    border-radius: var(--border-radius);
  }

  .membership-description--owned &__footer {
    background-color: #d7edd4;
  }

  &__price {
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
  }

  &__footer-details {
    display: flex;
    flex-grow: 1;
    align-items: baseline;
  }

  .per-month {
    padding-right: 2rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .coming-soon {
    font-style: italic;
  }
}
