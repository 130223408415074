@import "assets/scss/common.scss";

.GridView__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  &.viewScreen {
    position: absolute;
    height: 100%;
    overflow: auto;
    .actions {
      z-index: 0;
    }
  }
}

.slick-slider {
  &.zoom {
    .slick-list {
      overflow: unset;
      padding: unset;
      .slick-track {
        margin: unset;
      }
    }
  }
}
