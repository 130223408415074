.Connectivity__wrapper{
    height:1em;
    width:1.5em;
    background-color:white;

    .low_wifi_enabled {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3px;
        width: 1.1em;
        height: 0.9em;
    }

    .low_wifi_disabled {
        display: none;
    }
}

