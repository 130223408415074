.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000a;
}

.start-lab {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-out;
  z-index: 100;

  &__modal {
    font-size: 2rem;
    background-color: #fffcf5;
    width: 100%;
    max-width: 40rem;
    text-align: center;
    padding: 2rem;
    transform: translateY(calc(50vh + 50%));
    transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    place-items: end;
  }

  &--visible {
    opacity: 1;
    pointer-events: auto;
  }

  &--visible &__modal {
    transform: translateY(0);
  }
}

