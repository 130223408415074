.avatar-picker {
    position: fixed;
    z-index: 1000;
    border: none;
    background-color: #d3dcde;
    box-shadow: 0 10px 24px #0003;

    &::backdrop {
        background-color: #0008;
        backdrop-filter: blur(3px);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem 0.75rem 1rem;
    }

    &__title {
        margin: 0;
    }

    &__close-button {
        color: var(--clr-black);
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 600;
    }

    &__buttons {
        display: grid;
        grid-template-columns: repeat(3, 200px);
        gap: 0.5rem;
    }

    &__button {
        --glow: #fffc;

        position: relative;
        margin: 0;
        padding: 0;
        border: none;
        color: var(--clr-black);
        overflow: hidden;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        background-color: transparent;

        &::after {
            content: "";
            position: absolute;
            left: 5%;
            right: 5%;
            top: 5%;
            bottom: 5%;
            opacity: 0;

            background-image:
                conic-gradient(
                    var(--glow) 0deg   30deg,
                    transparent 30deg  60deg,
                    var(--glow) 60deg  90deg,
                    transparent 90deg  120deg,
                    var(--glow) 120deg 150deg,
                    transparent 150deg 180deg,
                    var(--glow) 180deg 210deg,
                    transparent 210deg 240deg,
                    var(--glow) 240deg 270deg,
                    transparent 270deg 300deg,
                    var(--glow) 300deg 330deg,
                    transparent 330deg 360deg
                ),
                radial-gradient(var(--glow) 20%, transparent 70%)
            ;
            border-radius: 100%;
            animation-name: avatar-spin;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transition: opacity 75ms ease-in-out;
        }

        &:hover{
            .avatar-picker__avatar {
                filter: brightness(1);
            }

            &::after {
                opacity: 1;
            }
        }

        &--avatar {
            
        }
    }

    &__avatar {
        filter: brightness(0.8);
        position: relative;
        z-index: 1;
        display: block;
        transition: filter 75ms ease-in-out;
    }

    &__button-text {
        position: relative;
        z-index: 1;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@keyframes avatar-spin {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(1.1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}
