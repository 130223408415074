.Summary__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  //padding-bottom:56%;
  margin: 0 auto;
  background-color: #0a324d;
  color: white;

  .left-panel {
    position: absolute;
    width: 60%;
    height: 100%;
    left: 0;
    text-align: center;
  }
  .right-panel {
    position: absolute;
    top: 20%;
    width: 40%;
    height: 100%;
    right: 0;
  }
}
.Agreement__wrapper {
  position: relative;
  width: 25%;
  padding-bottom: 25%;
  float: left;
  transform-style: preserve-3d;

  .flip-card {
    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
      .flip-card-inner {
        transform: rotateY(180deg) scaleX(-1);
      }
    }
  }

  .no-flip-card {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.2s;
    transform-style: preserve-3d;
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-back {
    transform: rotateY(180deg) scaleX(-1);
  }
  img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    filter: drop-shadow(0.1em 0.1em 0.1em black);
    /* -webkit-backface-visibility: hidden; */
    backface-visibility: hidden;
  }
}
