@import 'assets/scss/common.scss';

.Authenticator{
  position: relative;
  z-index: 2;
  font-size: 1rem;

  @include mobile_portrait { // < 481px
    top: 0;
    left: 0;
    transform: translate(0,0);
    width:100vw;
    height:100vh;
    border-radius: 0;

  }
  .Auth__wrapper{
    color: var(--clr-white);
    max-width: 50ch;
    margin: auto;
    margin-top: 10vh;

    input{     
      border: none;
      border-radius: 0;
      background-color: #fffd;
      color: var(--clr-black);
      &:hover,
      &:focus {
        background-color: var(--clr-white);
      }

      &::placeholder {
        color: #000a;
      }
    }

    .signup_wrapper{
      position:relative;
      height:min-content;
      width:100%;
      .title{
        width: 100%;
        margin:.5em 0;
        text-align: center;
        color: white;
        font-weight:700;
      }
      .message{
        font-size:.35em;
      }
      .action_btn{
        position:absolute;
        bottom:0;
        width:100%;
        font-size:1.25em;
        margin-bottom:.25em;
        .Action__wrapper{
          display:flex;
          justify-content:center;
        }
      }
     
    }
    .avatar{
      position:relative;
      width: calc(100% - .5em);
      margin-left: .25em;      
      height:3.5em;
    }
    .title-container {
      position: relative;      
      display: flex;
      flex-direction: column;
      .title-logo{
      }
      .logo {
      }
      .title{
        font-size: 1.5rem;
        text-align: center;
        margin-bottom:1.2em;        
      }
      .prompt {
      }
    }
    [class^="Section__container__"]{
      position:relative;
      z-index:1;
      .action_btn{
        position:relative;
        bottom:0;
        width:100%;
        font-size:1.25em;
        margin-bottom:.25em;
        .Action__wrapper{
          display:flex;
          justify-content:center;
        }
      }

      [class^="Form__formContainer__"]{
        margin:0;
      }
    
      [class^="Form__formSection"]{
        background-color:#161e4f;
        padding-top:0;
        [class^="Section__sectionHeader__"]{
          display:None;
        }
        [class^="Section__sectionFooterPrimaryContent__"]{
          position: absolute;
          bottom: -7em;
          width: 100%;
          margin-left: .5em;
          display: flex;
          justify-content: center;
          
        }
        [class^="Input__inputLabel"]{
          color: #ffffff;
          font-family: Ebrima;
          font-size: .3em;
          font-weight: 700;
        }
        [class^="Input__input__"]{
          border: none;
          color: #ffffff  !important;
          font-family: Ebrima;
          font-size: .3em;
          font-weight: 700;
         
          padding: .33em 1em .33em .25em;
          background-size: 1em 1em;
          background-position: right;
          background-color: #32343b  !important;
          border:solid #32343b .3em  !important;
          &::placeholder {
            color: #b8effb;
          }
        }
        [name^="dial_code"]{
          border: none;
          color: #ffffff;
          font-family: Ebrima;
          font-size: .3em;
          font-weight: 700;
          text-transform: uppercase;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25),
                        1px -1px 2px rgba(0, 0, 0, 0.25),
                        -1px 1px 2px rgba(0, 0, 0, 0.25),
                        -1px -1px 2px rgba(0, 0, 0, 0.25);
          padding: .33em 1em .33em .25em;
          background-size: 1em 1em;
          background-position: right;
          background-color: #32343b;
          border:solid #32343b .3em;
          border-top-left-radius: .6em;
          border-bottom-left-radius: .6em;
          &::placeholder {
            color: #b8effb;
          }
        }
        [class^="Button__button__"]{
            text-align: center;
            position:relative;
            float:right;        
            justify-content: center;
            margin-left:1em;
            font-family: 'Ebrima';
            font-size: 2em;
            text-transform: uppercase;
            padding: .25em;
            border:solid .075em #0673d0;
            background-color:#161e4f;
            cursor: pointer;    
            &:after{
              content: '';
              border: solid .075em #0673d0;
              //background-color: rgba(0, 0, 0, 0.6);
              width: calc(100% - .275em);
              position: absolute;
              left: 0;
              top: 0;
              height: calc(100% - .275em);
              margin: .075em;
            }
            
        }
      }
    }
    .actions{
      position:absolute;
      z-index:1002;
      top:.15em;
      right:.15em;
      width:.6em;
      height:.6em;
      text-align: center;
      //margin-top: 0;
      //margin: 5% auto 50px;
      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;    
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 60%;
          height: .02em;
          transform: translate(-50%, -50%) rotate(45deg);
          transform-origin: center center;
          background: #ffffe6;
          box-shadow:0 0 .03em .01em #ffd900          
        }
        &:hover:before {
            box-shadow:0 0 .03em .01em #00a3bf
          }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 60%;
          height: .02em;
          transform: translate(-50%, -50%) rotate(-45deg);
          transform-origin: center center;
          background: #ffffe6;
          box-shadow:0 0 .03em .01em #ffd900
        }
        &:hover:after {
            box-shadow:0 0 .03em .01em #00a3bf
          }
      
      }
    }
  }
}
.hide{
  display:none;
}
