.play {
  --black: #000;
  --white: #fff;
  --primary: #009dad;
  --secondary: #fbae17;
  --success: #37ff8b;

  &-mobile-share-container {
    display: flex;
    justify-content: center;
    padding: 1.5em 0;
    background-color: var(--secondary);
    border-bottom: 1px solid var(--black);

    @media (min-width: 768px) {
      display: none;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    padding-bottom: 1.5em;

    @media (min-width: 768px) {
      padding-bottom: 2.5em;
    }

    &__link {
      color: var(--black);
      text-decoration: none;
      position: relative;
      display: block;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.25em;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transform: scaleX(0);
        transition: transform 75ms ease-in-out;
      }

      &:hover::after {
        transform: scaleX(1);
      }
    }
  }

  &-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 1.25em;
    z-index: 100;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &--show {
      pointer-events: auto;
    }

    &__background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0;

      transition: opacity 200ms ease-in;
    }

    &__box {
      background-color: var(--secondary);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em;
      padding-top: 2.5em;
      margin: 1em;
      border-radius: 1em;
      box-shadow: 0 0.2em 1em #0004;
      transform: translateY(calc(50vh + 100%));
      transition: transform 200ms ease-in;
      z-index: 101;
    }

    &--show {
      .play-modal__background {
        opacity: 0.5;
      transition: opacity 200ms ease-out;
      }

      .play-modal__box {
        transform: translateY(0);
        transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
      }
    }

    &__close {
      position: absolute;
      top: 0.75em;
      right: 0.75em;
      cursor: pointer;
      background: none;
      border: none;
      font-size: inherit;
      font-family: inherit;
      font-weight: 500;
    }
  }

  &-share-modal {
    &__description {
      margin: 0;
      padding: 0;
      margin-bottom: 1.5em;

      @media (min-width: 768px) {
        font-size: 1.2em;
      }
    }

    &__field {
      position: relative;
    }

    &__url {
      font-size: inherit;
      border: none;
      padding: 0.5em 1em;

      &::selection {
        background-color: var(--primary);
      }
    }

    &__copy-confirmation {
      background-color: var(--success);
      padding: 0.125em;
      border-radius: 0.25em;
      box-shadow: 0 0 0 2px var(--white), 0 0 0 4px var(--success);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      animation: play-fade 1.5s forwards;
    }
  }

  &-input--copy {
    position: absolute;
    left: -100vw;
  }
}

@keyframes play-fade {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.share-body {
  display: flex;
  column-gap: 4rem;
  row-gap: 2rem;
  flex-direction: column;
  max-width: calc(75vh * 16 / 9 - 6rem);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__text {
    flex-grow: 1;
  }

  &__title {
    margin: 0;
    margin-bottom: 1rem;
  }

  &__description {
    margin: 0;
    line-height: 1.6;
  }
}
