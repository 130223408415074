
.ClassEnd__wrapper{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border: solid transparent .5vw;
  padding-top:.25vw;
  //padding-bottom:56%;
  margin: 0 auto;
  background-color:#0a324d;
  text-align:center;
  color:white;
   
  
  }