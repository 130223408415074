.unlock-form-section {
  position: relative;
  z-index: 5;
  color: var(--clr-black);
}

.unlock-form {
  display: grid;
  max-width: 35ch;
  height: calc(100% - (3.375rem + 1.5rem));
  margin: 0 auto;
  grid-template-rows: max-content 1fr;

  ul {
    padding: 0;
    list-style-type: none;
  }

  &__title,
  &__subtitle {
    text-align: center;
    text-transform: uppercase;
  }

  &__title {
    height: 2rem;
    margin: 0;
    color: var(--clr-accent);
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__subtitle {
    font-weight: 600;
  }

  &__content {
    --border-width: 2px;

    display: flex;
    min-width: calc(min(28rem, calc(100vw - 4rem)));
    flex-direction: column;
    padding: 1rem 2rem;
    background:
      linear-gradient(to right, var(--clr-black) 50%, transparent 0%),
      linear-gradient(var(--clr-black) 50%, transparent 0%),
      linear-gradient(to right, var(--clr-black) 50%, transparent 0%),
      linear-gradient(var(--clr-black) 50%, transparent 0%)
    ;
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 10px var(--border-width), var(--border-width) 10px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    &--centered {
      align-items: center;
    }
  }

  &__field {
    margin-bottom: 1rem;
  }

  &__label {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;

    &--checkbox {
      display: grid;
      grid-template-columns: min-content 1fr;
      text-align: left;
    }
  }

  &__input {
    height: auto;
    padding: 1rem;
    border: none;
    border-radius: 0;
  }

  &__checkbox {
    width: 2em;
    height: 2em;
    margin: 0;
    margin-right: 0.25rem;
  }

  &__link {
    color: var(--clr-black);
    font-size: 0.875rem;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 1rem;
    flex-grow: 1;
  }

  &__button {
    display: flex;
  }

  &__price-group {
    text-align: center;
  }

  &__free {
    padding: 0.75em 3em;
    background-color: var(--clr-white);
    color: var(--clr-success);
    font-weight: 600;
    text-align: center;
  }

  &__loader {
    position: relative;
    width: 2em;
    height: 2em;
    margin-left: 1em;
    animation: loader-spin 1.4s infinite linear;
    background: linear-gradient(
      to right,
      var(--clr-black) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    border-radius: 50%;
    font-size: 10px;
    text-indent: -9999em;
    transform: translateZ(0);

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }

    &::before {
      width: 50%;
      height: 50%;
      background: var(--clr-black);
      border-radius: 100% 0 0 0;
    }

    &::after {
      right: 0;
      bottom: 0;
      width: 75%;
      height: 75%;
      margin: auto;
      background: #bbb;
      border-radius: 50%;
    }
  }
}

.receipt {
  &__email {
    font-weight: 500;
    text-align: center;
  }
}

@keyframes loader-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

