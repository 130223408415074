// animations
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }  
  100% {
    opacity: 0;
  }
}

@keyframes hideLogo {
  0% {
    opacity: 1;
    transform: translate(-50%,-50%) scale(1);

  }  
  30% {
    opacity: 1;
    transform: translate(-50%,-50%) scale(1);
  }  

  100%{
    opacity: 0;
    transform: translate(-50%,110%) scale(.25);

  }
}

@keyframes neonBorder {
  from {
    box-shadow: 0 0 20px rgba(0, 30, 255, 0.6),inset 0 0 20px rgba(0, 30, 255, 0.6),0 0 14px #0090ff,inset 0 0 14px #0090ff, 0 0 3px white,inset 0 0 3px white;
    border:  solid white;

  }
  to {
    box-shadow: 0 0 40px rgba(0, 30, 255, 0.4),inset 0 0 40px rgba(0, 30, 255, 0.4),0 0 7px #0090ff,inset 0 0 7px #0090ff,0 0 3px white,inset 0 0 3px white;    
    border:  solid #b8effb;
  }
}

@keyframes neonBorderProminent {
  from {
    box-shadow: 0 0 40px rgba(0, 30, 255, 0.9),inset 0 0 20px rgba(0, 30, 255, 0.9),0 0 14px #0090ff,inset 0 0 14px #0090ff, 0 0 3px white,inset 0 0 3px white;
    border:  solid white;

  }
  to {
    box-shadow: 0 0 20px rgba(0, 30, 255, 0.3),inset 0 0 40px rgba(0, 30, 255, 0.3),0 0 7px #0090ff,inset 0 0 7px #0090ff,0 0 3px white,inset 0 0 3px white;    
    border:  solid #b8effb;
  }
}

@keyframes neonBorderDouble {
  from {
    box-shadow: 0 0 40px rgba(0, 30, 255, 0.4),inset 0 0 40px rgba(0, 30, 255, 0.4),0 0 14px #0090ff,inset 0 0 14px #0090ff, 0 0 3px white,inset 0 0 3px white;
    border: .3em solid #48a7ff88;

  }
  to {
    box-shadow: 0 0 40px rgba(0, 30, 255, 0.6),inset 0 0 40px rgba(0, 30, 255, 0.6),0 0 14px #0090ff,inset 0 0 14px #0090ff,0 0 3px white,inset 0 0 3px white;    
    border: .3em solid #b8effb;
  }
}



@keyframes greenBorder {
  from {
    box-shadow: 0 0 20px rgba(0, 255,30, 0.6),inset 0 0 20px rgba(0, 255,30, 0.6),0 0 14px #00ff90,inset 0 0 14px #00ff90, 0 0 3px white,inset 0 0 3px white;
    border:  solid white;

  }
  to {
    box-shadow: 0 0 40px rgba(0, 255,30, 0.1),inset 0 0 40px rgba(0, 255,30, 0.1),0 0 7px #00ff90,inset 0 0 7px #1c1f1d,0 0 3px white,inset 0 0 3px white;    
    border:  solid #b8effb;
  }
}


@keyframes redBorder {
  from {
    box-shadow: 0 0 20px rgba(255, 30,30, 0.6),inset 0 0 20px rgba(255, 30,30, 0.6),0 0 14px #ff9090,inset 0 0 14px #ff9090, 0 0 3px white,inset 0 0 3px white;
    border:  solid white;

  }
  to {
    box-shadow: 0 0 40px rgba(255, 30,30, 0.1),inset 0 0 40px rgba(255, 30,30, 0.1),0 0 7px #ff9090,inset 0 0 7px #1c1f1d,0 0 3px white,inset 0 0 3px white;    
    border:  solid #b8effb;
  }
}

