.Classrow__wrapper {
    position: absolute;
    bottom: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    .left {
        display: flex;
        position: absolute;
        width: 3em;
        height: 2em;
        left: 0;
        bottom: 0;
        transition: all 0.125s;

        &.zoom {
            display: flex;
            position: absolute;
            width: calc(60% - 3.6em);
            padding-bottom: 32%;
            bottom: 3.2em;
            left: 5%;
            transition: all 0.125s;
            &.center {
                left: 50%;
                transform: translate(-50%);
            }
        }
        .InstructorIcon {
            position: absolute;
            width: 100%;
            height: 100%;
            margin-left: 0.35em;
            //margin-top: -0.7em;
            border: unset;
            //transition: margin-top 0.125s;

            &.minimize {
                margin-top: 0;
                transition: margin-top 0.125s;
            }

            .cursor {
                position: absolute;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(./StudentIcon/images/mouse_cursor.png);
                width: 30%;
                padding-bottom: 30%;
                left: unset;
                right: 0;
                bottom: 0;
                z-index: 101;
                filter: drop-shadow(2px 4px 6px black);
            }
            .instructorBg {
                position: absolute;
                width: 100%;
                height: 100%;
                border: 0.175em solid transparent;
                border-bottom-right-radius: 0.175em;
                border-bottom-left-radius: 0.175em;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                img {
                    position: absolute;
                    bottom: 0.4em;
                    left: 20%;
                    width: 60%;
                }
                &.control {
                    background-color: #0a324d;
                }
                &.spotlight {
                    border-color: #0a324d;
                    background-color: #0a324d;
                }
            }
        }

        .username {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: white;
            font-size: 0.3em;
            font-weight: 700;
            text-align: center;
            height: 2em;
            line-height: 1.5em;
            margin-left: 1.2em;
            margin-bottom: 0.4em;
            z-index: 3;
            text-shadow: 1px 1px 10px #000, 1px 1px 10px #000;
            pointer-events: none; // Allows passthrough of clicks (doesnt block context menu)

            .volumeLevel {
                position: absolute;
                // top: .25em;
                left: 0.7em;
                bottom: 0.25em;
                width: 1.6em;
                height: 1.6em;
            }
        }
    }

    .centre {
        //font-size:46px;//revert to original em
        position: absolute;
        display: flex;
        left: 50%;
        height: 100%;

        transform: translate(-50%, 0);

        .StudentIcon {
            position: relative;
            bottom: 0.6em;
            width: 2em;
            height: calc(100% + 0.5em);
            font-size: 0.85em;
            &.narrow {
                width: 1.75em;
            }

            &.me {
                width: 2.75em;
            }
        }
    }

    .right {
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        width: 2em;
        height: 100%;
        right: 0;
        border: solid 0.175em transparent;
        .gridViewBtn {
            position: absolute;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("./images/Grid_button_normal.png");
            &:hover {
                background-image: url("./images/Grid_button_hover.png");
            }
            &:active {
                background-image: url("./images/Grid_button_pressed.png");
            }
            &.return {
                background-image: url("./images/GridRtrn_button_normal.png");
                &:hover {
                    background-image: url("./images/GridRtrn_button_hover.png");
                }
                &:active {
                    background-image: url("./images/GridRtrn_button_pressed.png");
                }
            }
        }
    }
}
.participant {
    border-radius: 0.175em;
}
