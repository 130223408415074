@import 'assets/scss/common.scss';

.PortfolioGrid__wrapper {
  .arcade-card {
    position: relative;
    font-size:1.4em;
    width: calc(100% - .9em);
    margin-left:.45em;
    margin-top: 0;
    opacity: 0;
    //transition: all 0.3s ease;
    overflow: hidden;
    z-index: 11;
    &.expanded {
      opacity: 1;
      padding-top:48.3%;
    }
    .slick-track{
      height:max-content;
    }
    @include mobile_portrait { // < 481px
      width:100%;
      margin:0;
      font-size:1em;
    }   
    @include mobile_landscape { // < 481px
      width:100%;
      margin:0;
      font-size:1em;
      &.expanded {
      padding-top:56.5%;
      }

    }       
  }

}
