.membership-section {
  --clr-black: #3d3d3d;

  position: relative;
  z-index: 5;
  display: grid;
  padding: 2rem 0.5rem 0;
  margin-top: 1.5rem;

  &__container {
    &--filled {
      padding-top: 3.5rem;
      margin-top: 2rem;
      background: #fff8;
    }

    @media(min-width: 768px) {
        display: flex;
        justify-content: center;
        gap: 3rem;
    }
  }

  &__title,
  &__subtitle {
    margin: 0;
    text-align: center;
  }

  &__title {
    color: var(--clr-white);
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__subtitle {
    width: fit-content;
    padding: 0.5em 5em;
    margin-top: 0.25rem;
    margin-bottom: 2rem;
    color: var(--clr-black);
    font-size: 1.125rem;
    font-weight: 500;
    justify-self: center;
  }

  &__text {
    font-size: 1.125rem;
    color: var(--clr-white);
    text-align: center;
    margin: 2.5rem
  }

  &__plans {
    display: grid;
    justify-content: center;
    gap: 1rem;

    @media(min-width: 1200px) {
      grid-auto-columns: 23rem;
      grid-auto-flow: column;
    }
  }
}

