@import 'assets/scss/common.scss';

.input__wrapper{
    .placement{
        display:flex;
        align-items: center;
        justify-content: left;  
        .sizing{
            width:100%;
            .label{
                margin-bottom: 0.25rem;
                .text{                    
                }
            }
            input{
                padding: 0.5em;
            &.locked{
                //display:None;
                background-color:transparent;
                color:inherit;


            }
            
             &.valid{
                 outline: solid var(--clr-success) 2px;
                }  
                &.invalid{
                    outline:solid var(--clr-danger) 2px;
                }  
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                border: inherit;
            }
            .message{
                visibility: hidden;
                line-height: 0;
                color: var(--clr-danger);
                font-size: 0.75rem;

                .text{
                }
                &.show{
                    visibility:visible;
                    line-height: 1.5;

                }
            }
            

        }
    }

    & + .input__wrapper {
        margin-top: 1rem;
    }
}
