@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

html {
  scroll-behavior: smooth;
}

.layout {
  --clr-white: #fbfbfb;
  --clr-gray-lightest: #e4e4e4;
  --clr-black: #1a1a1a;
  --clr-gray-darkest: #313131;
  --clr-primary: #ffb640;
  --clr-accent: #a85aa3;
  --clr-danger: #b31d09;
  --clr-success: #76c16c;
  --clr-youtube: #f7395e;
  --ff-body: Rubik, arial, sans-serif;
  --built-in-logo-padding: 0.375rem;
  --body-padding: calc(0.5rem + var(--built-in-logo-padding));
  --height-header-primary: 4.375rem;
  --height-header-secondary: 2.4rem;
  --height-header-subnav: 0rem;

  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: #888;
  color: var(--clr-black);
  font-family: var(--ff-body);
  font-size: 1rem;
  user-select: text;

  @media (min-width: 768px) {
    --body-padding: clamp(2vw, 10vw, 7.5rem);
  }

  &--with-subnav {
    --height-header-subnav: 3.40rem;
  }

  &__bg-image {
    position: absolute;
    z-index: 0;
    display: block;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%) translateX(7rem);
    opacity: 0.1;
    pointer-events: none;

    &--high {
      bottom: 0;
    }
  }

  .content {
    flex-grow: 1;
    min-height: 80rem;

    &--fit {
      min-height: auto;
    }

    &--height-adjusted {
      padding-top: calc(var(--height-header-primary) + var(--height-header-secondary) + var(--height-header-subnav) - 1px);
    }
  }
}

.gradient {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(134deg, #00e2f9, #f75cad);
  mix-blend-mode: overlay;
  opacity: 0.23;
  pointer-events: none;
}

.header {
  --hamburger-offset: 1.5rem;

  position: fixed;
  z-index: 100;
  width: 100%;

  &__top-bar {
    padding-top: 1em;
    padding-right: var(--body-padding);
    padding-bottom: 1em;
    padding-left: var(--body-padding);
  }

  &__ctas {
    display: flex;
  }

  &__membership-cta {
    --flag-clr: var(--clr-success) !important;

    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 85%;
    margin: auto 0;
    color: var(--clr-white);
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;

    .cta-image {
      margin: 0 0.5rem;
      transform: scale(1.8);
    }
  }

  &__primary {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    padding-right: var(--hamburger-offset);
    padding-left: calc(var(--body-padding) - var(--built-in-logo-padding));
    background-color: #1a1a1ae6;
    box-shadow:
      inset 0 -0.375rem 0 #00000040,
      0 0.375rem 0 #00000040;
  }

  &__logo {
    width: 84px;
    height: 52px;
    margin: auto 0;
  }

  &__left,
  &__center,
  &__right {
    display: flex;
    height: 4.375rem;
  }

  &__center {
    gap: 0.5rem;

    @media (min-width: 1130px) {
      gap: 2.5rem;
    }
  }

  &__right {
    justify-content: flex-end;
  }

  &__login {
    display: inline-block;
    background-color: var(--clr-primary);
    height: fit-content;
    align-self: center;
    text-transform: uppercase;
    color: var(--clr-black);
    text-decoration: none;
    font-weight: 500;
    margin-right: 0.5rem;
    padding: 0.5em 1em;
    white-space: nowrap;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &__avatar {
    display: none;
    max-width: 100%;
    max-height: 100%;
    align-self: stretch;
    margin-right: 1rem;
    transform: scale(1.3);
    transform-origin: bottom;

    @media (min-width: 630px) {
      display: block;
    }

    @media (min-width: 1000px) {
      margin-right: 3rem;
    }
  }

  &__secondary {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-white);
    color: var(--clr-black);
    font-size: 0.75rem;
  }

  &__link {
    color: inherit;
  }
  
  .nav-link {
    --clr-hover: var(--clr-primary);

    position: relative;
    display: inline-flex;
    align-items: center;
    color: var(--clr-white);
    font-size: 1.125rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: 614px) {
      display: none;
    }

    &--watch {
      --clr-hover: var(--clr-youtube);
    }

    &__icon {
      display: none;
      width: 3rem;
      opacity: 1;

      @media (min-width: 900px) {
        display: block;
      }

      @media (min-width: 1150px) {
        margin-right: 0.5rem;
      }

      &--hover {
        position: absolute;
        opacity: 0;
      }
    }

    &__text {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      font-size: 1rem;
      text-align: center;

      &::after {
        display: block;
        overflow: hidden;
        height: 1px;
        margin-top: -1px;
        background-color: red;
        color: transparent;
        content: attr(data-text);
        font-weight: 500;
        visibility: hidden;
      }
    }

    &:hover,
    &:focus {
      .nav-link__icon {
          opacity: 0;

          &--hover {
            opacity: 1;
          }
      }

      .nav-link__text {
        font-weight: 500;
      }
    }

    &:active,
    &--selected,
    &--selected:hover,
    &--selected:focus {
      .nav-link__text {
        color: var(--clr-hover);
        font-weight: 500;
      }
    }
  }

  .hamburger {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--clr-white);

    &__target {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;

      span {
        display: block;
        width: 3rem;
        height: 0.5rem;
        background: var(--clr-white);
        border-radius: 100vw;
      }
    }

    &__dropdown {
      position: fixed;
      top: 6.5rem;
      right: var(--hamburger-offset);
      min-width: 8rem;
      background-color: #1a1a1ae6;
      transform: scale(0);
      transform-origin: top;
    }

    &:hover {
      .hamburger__dropdown {
        transform: scale(1);
      }
    }

    &__link {
      display: flex;
      width: 100%;
      align-items: center;
      padding-top: 0.25rem;
      padding-right: 1.5rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      border: none;
      margin: 0;
      background: none;
      color: var(--clr-white);
      cursor: pointer;
      font-family: inherit;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: left;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background: var(--clr-primary);
        color: var(--clr-black);

        .hamburger__link-icon {
          color: var(--clr-white);
        }
      }
    }

    &__link-icon {
      margin-right: 0.75rem;
      font-size: 2.4rem;
      text-align: center;

      &--text {
        width: 45px;
        height: 45px;
      }
    }

    &__table {
      display: grid;
      font-size: 0.875rem;
      grid-template-columns: max-content 1fr;
      padding: 1rem;
      gap: 1rem;

      & > *:nth-child(odd) {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .dropdown-list {
      --border-style: 1px solid var(--clr-white);

      padding: 0;
      border-top: var(--border-style);
      margin: 0;
      list-style-type: none;

      &__item {
        border-right: var(--border-style);
        border-bottom: var(--border-style);
        border-left: var(--border-style);

        &--hidden-sm {
          display: none;

          @media(max-width: 614px) {
            display: block;
          }
        }
      }
    }
  }
}

.footer {
  position: relative;
  z-index: 2;

  &__primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--clr-gray-darkest);
  }

  &__secondary {
    display: flex;
    height: 4.375rem;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-white);
  }

  &__link-container {
    width: max-content;
  }

  &__link{
    display: inline-block;
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 0.75rem;
    text-decoration: none;

    &--primary {
      margin-bottom: 1rem;
      color: var(--clr-white);
      text-decoration: underline;
    }

    &--secondary {
      color: var(--clr-black);
    }
  }
}

.partner-list {
  display: flex;
  max-width: 60vw;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  margin-bottom: 1rem;
  column-gap: 43px;
  list-style-type: none;
  row-gap: 21px;
}

.password-reminder {
  display: none;
  height: 100%;
  align-items: center;
  padding-right: 4.5rem;
  padding-left: 1rem;
  background-color: var(--clr-accent);
  color: var(--clr-white);

  @media (min-width: 675px) {
    display: flex;
  }

  strong {
    margin-right: 0.25em;
    font-weight: 700;
  }
}

.password-reminder__icon {
  position: absolute;
  right: 3rem;
  width: 1.5em;
  animation: bounce-arrow 1s infinite alternate;
}

.hidden-sm {
  @media (max-width: 320px) {
    display: none;
  }
}

.cl-button {
  position: relative;
  display: inline-block;
  height: min-content;
  padding: 0.9em 1.2em;
  border: none;
  background-color: var(--clr-primary);
  color: var(--clr-black);
  cursor: pointer;
  font-family: var(--ff-body);
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 150ms ease-in-out, filter 150ms ease-in-out;
  white-space: nowrap;

  &--wide {
    padding: 0.9em 2.9em;
  }

  &--fullwidth {
    width: 100%;
  }

  &--accent {
    background-color: var(--clr-accent);
    box-shadow: inset 1px 1px var(--clr-white);
    color: var(--clr-white);
  }

  &[disabled] {
    background-color: #949494;
    pointer-events: none;
    color: var(--clr-white);
    box-shadow: inset 1px 1px var(--clr-white);

    &::after {
      content: none;
    }
  }

  &__icon {
    width: 1em;
    margin-right: 1.5em;
    margin-left: 0.25em;
    transform: scale(1.5);
    opacity: 1;

    &--hover {
      opacity: 0;
      position: absolute;
    }

    &--solo {
      margin-right: 0.25em;
    }
  }

  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #00000029;
    content: "";
    transform-origin: bottom;
    transition: height 150ms ease-out;
  }

  &:hover,
  &:focus {
    filter: brightness(1.1);
    transform: translateY(-0.25rem);

    &::after {
      height: calc(5px + 0.25rem);
    }
  }

  &:hover {
    .cl-button__icon {
      opacity: 0;

      &--hover,
      &--static {
        opacity: 1;
      }
    }
  }

  &:active {
    transform: translateY(5px);

    &::after {
      height: 0;
    }
  }
}

.flag {
  --flag-clr: var(--clr-primary);

  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.5em 2.51em;

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--flag-clr);
    content: "";
    -webkit-mask-composite: exclude;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    mask-composite: xor;
    -webkit-mask-image:
    -webkit-linear-gradient(black, black),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="48" viewBox="0 0 2 4"><polygon points="0,0 2,2, 0,4" /></svg>'),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="48" viewBox="0 0 2 4"><polygon points="2,0 2,4, 0,2" /></svg>');
    mask-image:
      linear-gradient(black, black),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="48" viewBox="0 0 2 4"><polygon points="0,0 2,2, 0,4" /></svg>'),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="48" viewBox="0 0 2 4"><polygon points="2,0 2,4, 0,2" /></svg>');
    -webkit-mask-position:
      top left,
      left center,
      right center;
      mask-position:
      top left,
      left center,
      right center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.section {
  position: relative;

  &__banner {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: -50vw;
      width: 200vw;
      height: 100%;
      background-color: #0006;
      content: "";
    }
  }

  &__title {
    padding: 1.75rem 0;
    margin: 0;
    color: var(--clr-white);
    font-size: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__body {
    padding: 0 0.5rem 3rem;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: -50vw;
      width: 200vw;
      height: 100%;
      background-color: #ffffff70;
      content: "";
      mix-blend-mode: overlay;
    }
  }
}

.breadcrumb {
  padding: 1rem var(--body-padding);
  background: #0008;
  font-size: 18px;

  &__item {
    text-decoration: none;
    color: var(--clr-white);

    &:not(:last-child)::after {
      padding: 0 1em;
      content: ">";
    }

    &--current {
      color: var(--clr-primary);
    }
  }
}

@keyframes bounce-arrow {
  from {
    transform: translateY(1.25rem);
  }

  to {
    transform: translateY(0);
  }
}
