.activity-edit-form {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50ch;
  margin: 2rem auto;

  &__thumbnail {
    display: block;
    max-width: 100%;
    margin-top: 0.5rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__label {
    display: inline-block;
    margin: 0;
    font-size: 0.875rem;
  }
}

.game-play-link {
  text-align: center;
  color: var(--clr-white);

  &__label,
  &__link {
    margin: 0;
  }

  &__label {
    font-size: 0.875rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  &__link {
    padding: 1rem;
    border: 4px solid var(--clr-primary);
    font-size: 18px;
    font-weight: 500;
  }
}
