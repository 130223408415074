@import 'assets/scss/common.scss';
@keyframes wave {
    from {
        transform-origin: bottom;
        transform: rotateZ(-4deg) translateY(-.05em);
  
    }
    to {
        transform-origin: bottom;
        transform: rotateZ(4deg) translateY(.05em);
    }
  }
.StudentIcon__wrapper {
    
    position:relative;
    animation: fadeIn .5s ease-out;
    float:left;
    width:100%;
    height:100%;
    &.control{
        border: solid white 2px;
        border-radius: .25em;
    }
    &.dimmed{
        .avatar{
            filter:grayscale(.6) blur(1px);
        }
    }
    .avatar {
        position: absolute;
        bottom: .5em;
        left: 0;
        width: 100%;
        height: calc(100% - .5em);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .avatar.projector{
        position: absolute;
        top: -25%;
        background-image:url(./images/projector_glow.png);
    }
    .avatar.chair{
        background-image:url(./images/avatar_chair.png);
    }
    .avatar.cursor{
        background-image:url(./images/mouse_cursor.png);
        width:40%;
        height:40%;
        left:unset;
        right:0;
        top:0;
    }
    .avatar.arm{
        background-image:url(./images/avatar_arm.png);
        opacity:0;
        translate:scaleY(0);
        transition: .25s ease-in-out;
        &.show{
            animation: wave .75s ease-in-out infinite alternate;
            translate:scaleY(1);
            opacity:1;

        }   
    }
    .avatar.head{
        background-image:url(./images/avatar_head.png);
    }

    .avatar.mic {
        position:absolute;
        left:0;
        bottom:.55em;
        width: .5em;
        height: .5em;
    }

    .avatar.audio {
        position:absolute;
        right: 0;
        left: unset;
        bottom:.5em;
        width: .6em;
        height: .6em;
    }

    .avatar.connection {
        position:absolute;
        // left:0.075em;
        top:.5em;
        width: .45em;
        height: .45em;

        &.poor {
            background-image:url(./images/wifi_icon.png);
        } 
    }

    .username{
        position:absolute;
        bottom:0;
        width:100%;
        color:white;
        font-size:.3em;
        font-weight:700;
        text-align:center;
        height:1.5em;
        line-height:1.5em;
    }

    .warning {
        position:absolute;
        left:0;
        top:.175em;
        width: .4em;
        height: .4em;
        background-color: red;
        border-radius: 2em;
        border: solid white 2px;
        &:hover{
            background-color:green;
            pointer-events:none;
            z-index:100000;
        }
    }

    &.spotlight{
        bottom: .175em;
    
        .spotlight{
            background: #0a324d;
            border-bottom-left-radius: .25em;
            border-bottom-right-radius: .25em;
            height: calc(100% - .5em);
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: -1;
            padding:.25em;
            
        }  
    }
}

.StudentIcon{
    &.me{
        &:hover{
            filter:brightness(.8);
            font-size:1.1em;
        }
        .StudentIcon__wrapper{
            .spotlight{
                bottom:0;
                .spotlight{
                    padding:0;
                }
            }
        }
        .avatar{
           margin-left:.5em; 
           width:calc(100% - .5em);
            &.cursor{
                width:calc(40% - .5em);
            }

            &.mic {
                width:.5em;
                margin-left: 0;
            }

            &.audio {
                width:.6em;
                margin-left: 0;
            }

            &.connection {
                width:.45em;
                margin-left: 0; // Dont update here, already set base value above in avatar.connection
            }
        }
        .username{
            bottom: .25em;
            width: calc(100% - .5em);
            margin-left: .25em;
            background-color: white;
            color: black;
            border-radius: .6em;
        }
    }
}