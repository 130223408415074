@import "assets/scss/common.scss";

.LobbyView__wrapper {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease-out;
  overflow: hidden;
  padding: 0;
  .title {
    text-align: center;
    padding: 0.25em;
    margin-bottom: 0.25em;
  }
  .slick-slider {
    height: 100%;
    .slick-active {
      z-index: 1;
    }
    .slick-arrow {
      z-index: 1;
      left: unset;
      &.slick-next {
        right: 0;
      }
    }
  }
}
