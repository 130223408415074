@import "assets/scss/common.scss";

.Carousel__wrapper {
  .neonBorder {
    border-radius: 0.15em;
  }

  .neonBorderProminent {
    border-radius: 0.15em;
  }

  .slick-slider {
    margin-left: 3.25em;
    z-index: 10;
    @include mobile_portrait {
      // < 481px
      margin-left: 0;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
    .slick-prev,
    .slick-next {
      width: 80px;
      height: calc(100% - 38px);
      background: rgba(0, 0, 0, 0.4);
      transition: 0.3s ease-in;
      z-index: 11;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }

      &::before {
        font-size: 30px;
      }
    }
    .container {
      border-radius: 0.15em;
    }
    video {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.15em;
      border: 0.04em solid rgba(0, 0, 0, 0);
      object-fit: cover;
    }
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 0.15em;
      border: 0.04em solid rgba(0, 0, 0, 0);
      object-fit: cover;
    }
  }
}

.PortfolioGrid__wrapper.featured {
  font-size: 1.45em;
  @include mobile_portrait {
    // < 481px
    font-size: 1.15em;
  }
  .feedback {
    display: None;
  }
  .slider__label {
    font-size: 0.45em;
    margin-left: 1em;
    text-transform: capitalize;
  }
  .slick-slider {
    margin-left: 0.45em;
    @include mobile_portrait {
      // < 481px
      margin-left: 0em;
    }
  }

  .container {
    //height: calc(4em * 0.65);
    //border-radius: 0.15em;
  }
}
