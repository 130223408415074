.avatar__wrapper{

    .profile {       
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .details {
            
            .title {
                
                    color: #ffffff;
                    font-family: 'Chaparral Pro';
                    font-size: 39px;
                    font-weight: 700;
                    margin: 0;
                
                
            }
        }            
    }
    .details {
      //margin-left: 20px;
  
      .title {
        h3 {
          color: #ffffff;
          font-family: 'Chaparral Pro';
          font-size: 39px;
          font-weight: 700;
          margin: 0;
        }
        p {
          font-family: Ebrima;
          font-size: 21px;
          font-weight: 400;
          color: #ffffff;
          margin: 10px 0px;
          text-align: left;
        }
      }
      
    }
  
  
  
    .feedback {
      position: absolute;
      right: 88px;
      bottom: 105px;
      width: 153px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      > div {
        display: flex;
        align-items: center;
  
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        span {
          color: #ffffff;
          font-family: Arial;
          font-size: 17px;
          font-weight: 700;
          line-height: 24px;
          margin-left: 13px;
        }
      }
    }
  
}

