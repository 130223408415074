.video__container {
  position: relative;

  &--overlay::before {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--clr-black);
    background-image: var(--bg-image);
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 500px black;
    content: "";
    filter: brightness(0.8);
  }

  &--clickable::before {
    box-shadow: none;
    cursor: pointer;
    filter: brightness(1);
  }

  .video__icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    max-height: 100%;
    object-fit: contain;
    transform: translate(-50%, -50%);
  }

  .youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  .youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

