.community-info {
  position: relative;
  padding: 2rem 0;
  margin-top: 2rem;
  background: #fff8;
  color: var(--clr-black);

  &__title {
    margin: 0;
    margin-bottom: 2.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__content {
    display: grid;
    width: max-content;
    margin: 0 auto;
    column-gap: 4rem;
    row-gap: 2rem;

    @media(min-width: 1200px) {
      grid-template-areas:
      'image description'
      'image cta'
      ;
      grid-template-rows: 1fr max-content;
    }
  }

  &__image {
    width: 100%;

    @media(min-width: 1200px) {
      grid-area: image;
    }
  }

  &__description {
    max-width: 55ch;
    margin: 0;
    line-height: 1.5;
  }

  &__cta {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    background-color: var(--clr-success);
    box-shadow: 0.375em 0.375em 0 0 #0002;
    color: var(--clr-white);
    font-weight: 500;
    gap: 1rem;
    justify-self: flex-end;
  }
}
