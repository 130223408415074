.loader {
  --color: black;
  text-align: center;

  &--light {
    span {
      --color: white;
    }
  }

  &--bounce {
    span {
      display: inline-block;
      vertical-align: middle;
      width: 0.75rem;
      height: 0.75rem;
      margin: 2.25rem 0.15rem;
      background: var(--color);
      border-radius: 100%;
      animation: bounce-load 0.9s infinite alternate;

      @for $i from 1 to 3 {
        &:nth-of-type(#{$i + 1}) {
          animation-delay: #{0.3s * $i};
        }
      }
    }
  }
}

@keyframes bounce-load {
  from {
    opacity: 0.1;
    transform: translateY(0);
  }

  to {
    opacity: 0.9;
    transform: translateY(-21px);
  }
}
