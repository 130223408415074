.start-new-modal {
  position: absolute;
  z-index: 1000;
  position: relative;
  padding: 2rem;
  width: calc(10rem + 40ch + 10rem);
  left: -200vw;
  visibility: hidden;

  &--show {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    visibility: visible;
  }

  &__container {
    position: fixed;

    &--show {
      z-index: 100;
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 100;

    &--show {
      background-color: #0004;
      pointer-events: all;
    }
  }

  &__close {
    cursor: pointer;
    border: none;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    overflow-y: scroll;
    max-height: 80vh;
    margin: 0;
    padding: 0;
  }
}

.seed-project-card {
  display: grid;
  grid-template-columns: 0.5fr 1fr max-content;
  padding-bottom: 1.5rem;

  &__info {
    padding: 0 1rem;
  }

  &__title,
  &__description {
    margin: 0;
    padding: 0;
  }

  &__description {
    height: 5em;
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      text-align: right;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 1.5em;
      background: linear-gradient(to right, #fff0, #ffff 50%);
    }
  }

  &__title {
    margin-bottom: 0.125rem;
  }

  &__thumbnail {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    white-space: nowrap;
    background: var(--clr-primary);
    color: var(--clr-black);
    font-family: var(--ff-body);
    font-weight: 500;
    border: none;
    padding: 0.5rem;
    margin: 0.5rem;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;

    &:hover,
    &:focus {
      filter: brightness(1.1);
    }
  }
}
