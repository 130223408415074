@import 'assets/scss/common.scss';
.Action__wrapper{
    text-align: center;
    margin: 0 auto;

    button{
        text-align: center;
        justify-content: center;
        position:relative;
        float:right;        
        width:calc(45% - 2em);
        margin-left:1em;
        font-family: 'Ebrima';
        font-size: .35em;
        text-transform: uppercase;
        padding: .2em;
        border-radius: .15em;
        border:.075em;
        background-color:rgba(0,0,0,0);
        cursor: pointer;    
        &.disabled{
            display:none;
        }    
    }
    

}


        
