.LabEnd {
  &__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid transparent 0.5vw;
    padding-top: 0.25vw;
    margin: 0 auto;
    background-color: #0a324d;
    text-align: center;
    color: white;
  }

  &__message {
    margin: 3rem 0 5rem 0;
    padding: 0;
  }

  &__cta {
    border: none;
    cursor: pointer;
    font-size: inherit;
    background-color: #de8f14;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 0;
    border-radius: 0.2em;

    &:hover,
    &:focus {
      filter: brightness(1.1);
    }
  }
}
