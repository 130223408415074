.arcade-empty {
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--clr-white);
    width: fit-content;
    outline: 2px solid var(--clr-black);
  }

  &__title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  &__description {
    font-size: 1.125rem;
    margin: 0;

    a {
      color: var(--clr-accent);
    }
  }

  &__image {
    margin-top: 3rem;
  }
}
