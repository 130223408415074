.ContextMenuItemButton__wrapper{
    font-size:.35em;
    font-weight:700;
    width:100%;  
    padding-left: 1em;
    //height:2em;
    padding-right: 1em;

    line-height: 3em;
    color:rgb(41, 66, 87);
    border-top:solid 1px transparent;
    border-bottom:solid 1px transparent;
    text-align:center;
    &:hover{
        background:whitesmoke;
        border-top:solid 1px grey;
        border-bottom:solid 1px grey;
    }
    &.active{
        background:rgb(41, 66, 87);
        color:white;
    }
}

.ContextMenuItemConfirmButton__wrapper{
    font-size:.35em;
    font-weight:700;
    width:100%;  
    padding-left: 1em;
    //height:2em;
    padding-right: 1em;

    line-height: 3em;
    color:rgb(41, 66, 87);
    border-top:solid 1px transparent;
    border-bottom:solid 1px transparent;
    text-align:center;
    &:hover{
        background:whitesmoke;
        border-top:solid 1px grey;
        border-bottom:solid 1px grey;
    }
    &.active{
        background:rgb(41, 66, 87);
        color:white;
    }

    .confirmationLabel {
        font-size: 80%;
    }

    .confirmationButtons {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;

        &.yes {
            color: green;
        }

        &.no {
            color: red;
        }
    }
}

.ContextMenuItemSection__wrapper{
    font-size:.35em; 
    font-weight:700;
    text-transform:uppercase;   
    width:100%;
    height:4em;
    line-height: 4em;   
    padding-left: 1em;
}
.ContextMenuItemIcon__wrapper{
    display:flex;
    position:relative;
    font-size:.35em; 
    font-weight:700;
    text-transform:uppercase;   
    width:100%;
    height:2em;
    line-height: 2em;   
    padding-left: 1em;
    .icon{
        position:relative;
        float:left;
        width:2em;
        height:100%;
        background-color:red;
        background-position: center;
    }
    .label{
        position:relative;
        float:left;
        height:1em;
    }
}

.ContextMenuItemTitle__wrapper{
    top:-3em;
    font-size: .375em;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    background-color: grey;
    color: yellow;
    height: 3em;
    line-height: 3em;
    padding-left: 1em;
}