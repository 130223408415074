@import "assets/scss/common.scss";

.Studio__wrapper {
    position: absolute;
    width: 100%;
    max-width: 1366px;
    max-height: 768px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //font-size: calc(min(3.3vw, 46px));
    font-size: 3.3vw;
    /*
    position: relative;
    width: calc(100vh / .56);
    max-width:100%;
    height: 100%;
    max-height:calc(.56 * 100vw);
    left:50%;
    transform:translate(-50%,0);
    font-size: calc(100vw / 1366 * 46);
    */
    //overflow:hidden;
    @include large_screen {
        font-size: 46px;
    }
    .Screen {
        position: relative;
        z-index: 0;
        left: 0;
        width: 100%;
        transition: all 0.15s ease-out;
        border: 0.175em solid transparent;
        overflow: hidden;
        padding-bottom: 46%;

        /*
        position: relative;
        left: 0;
        width: 100%;
        height: 47.292vw;
        transition: all 0.15s ease-out;
        border: 0.175em solid transparent;
        overflow: hidden;
        padding: 0;
        */
        &.lobby {
            background-color: #624177;
            .Screen__wrapper {
                .slick-slider {
                    background-color: grey;
                }
            }
        }
        &.watch {
            background-color: #00aac7;
            .Screen__wrapper {
                .slick-slider {
                    background-color: grey;
                }
            }
        }
        &.create {
            background-color: darkOrange;
            .Screen__wrapper {
                .slick-slider {
                    background-color: grey;
                }
            }
        }
        &.play {
            background-color: #76c16c;
            .Screen__wrapper {
                .slick-slider {
                    background-color: black;
                }
            }
        }
        &.gridView {
            background-color: #0a324d;
            &.viewScreen {
                background-color: darkOrange;
            }
            .Screen__wrapper {
                .slick-slider {
                    background-color: #0a324d;
                }
            }
        }
        &.portfolio {
            background-color: #97E18C;
            .Screen__wrapper {
                .slick-slider {
                    background-color: black;
                }
            }
        }
    }

    .Classrow {
        position: relative;
        left: 0;
        width: 100%;
        height: 1.65em;
        transition: all 0.15s ease-out;
        &.lobby {
            background-color: #624177;
        }
        &.watch {
            background-color: #00aac7;
        }
        &.create {
            background-color: darkOrange;
        }
        &.play {
            background-color: #76c16c;
        }
        &.gridView {
            background-color: #0a324d;
            &.viewScreen {
                background-color: darkOrange;
            }
        }
        &.portfolio {
            background-color: #97E18C;
        }
    }
    .ReactTooltip {
        font-size: 1em;
        min-width: 10vw;
        padding: 0;
        border-radius: 0;
        background-color: lightgrey;
        opacity: 1;
    }
}
