// @import 'assets/scss/common.scss';


.RoomVideo__wrapper{
  video {
    display:block;
    position:absolute;
    top:0;
    width:100%;
    height:100%;
  }

  &.show{
      position:absolute;
      width:100% !important;
      height:100%;
      z-index:2;
      border-radius:.175em;
      bottom:.15em;
  }

  &.dim {
    // Set externally
    opacity: 0.5;
  }

  &:hover {
    // Set internally
    opacity: 0.5;
  }
}

// .RoomVideo__wrapper {
//   video {
//     display:block;
//     position:absolute;
//     top:0;
//     width:100%;
//     height:100%;
//   }

  // position: absolute;
  // z-index: 1;
  // top: 70px;
  // left: 0px;
  // height: 30%;

  // position: fixed;
  // display:block;
  // width: 100%;
  // height: 150%;
  // top: -150%;
  // font-size:.15em;
  // pointer-events: none;

  // video {
  //   display:none; 
  // }


//      }


// .Room__wrapper.showVideo{
//   video{
//     display:block;
//     position:absolute;
//     top:0;
//     width:100%;
//     height:100%;
//   }
// }          