.Tab__wrapper {
    position: relative;
    height: 0.73em;
    margin-top: 0.27em;
    width: 2.6em;
    margin-left: 0.05em;
    margin-right: 0.05em;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    // &:hover {
    //     // filter: brightness(80%);
    //     // background-size: contain;
    // }

    &.active {
        height: 0.85em;
        margin-top: 0.15em;
    }

    &.greyed {
        background-color: grey !important;
    }

    &.greyed-student {
        background-color: grey !important;

        &:hover {
            filter: unset;
        }
    }

    .tab-icon {
        position: absolute;
        width: 45%;
        height: 100%;
        // top: 0.05em;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        &.tab-icon-hover {
            &:hover {
                // filter: brightness(80%);
                width: 100%;
            }
        }

        &.tab-icon-active {
            width: 100%;
        }
    }

    .lock {
        position: absolute;
        bottom: 0.075em;
        left: 0.1em;
        width: 0.6em;
        height: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;

        &.on {
            background-image: url(../images/locked_normal.png);

            &:hover {
                background-image: url(../images/locked_hover.png);
            }

            &:active {
                background-image: url(../images/locked_pressed.png);
            }
        }

        &.off {
            background-image: url(../images/unlocked_normal.png);

            &:hover {
                background-image: url(../images/unlocked_hover.png);
            }

            &:active {
                background-image: url(../images/unlocked_pressed.png);
            }
        }
    }

    .pin {
        position: absolute;
        bottom: 0.075em;
        right: 0.1em;
        width: 0.45em;
        height: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;

        &.on {
            background-image: url(../images/pinned_normal.png);
        
            &:hover {
                background-image: url(../images/pinned_hover.png);
            }

            &:active {
                background-image: url(../images/pinned_press.png);
            }
        }

        &.on-student {
            background-image: url(../images/pinned_normal.png);
        }

        &.off {
            background-image: url(../images/unpinned_normal.png);
        
            &:hover {
                background-image: url(../images/unpinned_hover.png);
            }

            &:active {
                background-image: url(../images/unpinned_press.png);
            }
        }
    }
}
