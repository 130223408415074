@import 'assets/scss/common.scss';

.slide-GameCard_pg__wrapper {
  margin: 0 auto;
  //width: 338px;
  padding: .5vw .25vw;

  .container{
    position:relative;
    cursor:pointer;
    width:5.5em;
    height:calc(5.5em * .75);
    overflow:hidden;
    &:hover{
      //transform: skew(-3deg,6deg);
    } 
    .title-overlay {
      width: 75%;
      object-fit: contain;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: rgb(155, 131, 131);
      text-shadow: 0 0 .25em #fff, 0 0 .3em #fff, 0 0 .5em #e60073, 0 0 .2em #e60073, 0 0 .1em #e60073, 0 0 .2em #e60073, 0 0 .3em #e60073;
      font-size: .65em;
    }
   
  
    .details {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      padding: .2em;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
 
      .title {
        span {
          color: white;
          font-family: Arial;
          font-size: .5em;
          font-weight: 700;
        }
      }

      .user-details {
        color: white;
        position: absolute;
        left: 3.5em;
        bottom: 0.75em;
        font-size: 0.4em;
        font-weight: bold;
      }

      .user-avatar {
        position: absolute;
        bottom: 0.2em;
        left: 0.2em;
        width: 1em;
      }

      .feedback {
        position:absolute;
        bottom:0;
        right:0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:.15em;
        > div {
          display: flex;
          align-items: center;

          img {
            width: .3em;
            height:.3em;
            object-fit: contain;
          }
          span {
            color:white;
            text-shadow: 1px 1px 4px black;
            font-family: Arial;
            font-size: .25em;
            font-weight: 700;
            //line-height: .4em;
          }
        }
      }
    }
  }
  
}
