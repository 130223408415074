.ToolMenuItemButton__wrapper{
    font-size:.35em;
    font-weight:500;
    text-transform:uppercase;    
    width:100%;  
    padding-left: 1em;
    height:2em;
    line-height: 2em;
    cursor: pointer;
    &:hover{
        filter:brightness(.8);
    }
}
.ToolMenuItemPassword {
    &__wrapper {
        font-size:.35em;
        font-weight:500;
        width:100%;
        padding-left: 1em;
        padding-bottom: 0.5em;
        line-height: 2em;
        text-shadow: 0 0 12px #ffffff60;
    }

    &__label {
        text-transform: lowercase;
        color: #bbb;
    }

    &__value {
        color: #00aac7;
    }
}
.ToolMenuItemSection__wrapper{
    font-size:.35em; 
    font-weight:700;
    text-transform:uppercase;   
    width:100%;
    height:4em;
    line-height: 4em;   
    padding-left: 1em;
}

.ToolMenuItemTitle__wrapper{
    font-size: .375em;
    font-weight: 700;
    width: 100%;
    background-color: grey;
    color: yellow;
    height: 3em;
    line-height: 3em;
    padding-left: 1em;
}