.learn {
  --max-width: 100ch;

  &__section {
    position: relative;
  }

  &__class-grid {
    max-width: var(--max-width);
    padding: 2rem 0 1rem;
    margin: 0 auto;

    @media (max-width: 619px) {
      display: block;
      width: fit-content;
      padding-bottom: 4rem;
    }
  }

  &__header {
    display: grid;
    grid-template-areas: 'title' 'description' 'image';
    grid-template-rows: min-content 1fr;
    column-gap: 2rem;
    max-width: var(--max-width);
    min-height: 18rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 900px) {
        grid-template-areas:
          'title image'
          'description image'
        ;
    }
  }

  &__image {
    grid-area: image;
    align-self: flex-end;
  }

  &__bar {
    background-color: #0006;
  }

  &__banner {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width);
    margin: 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: -50vw;
      width: 200vw;
      height: 100%;
      background-color: #0006;
      content: "";
    }
  }

  &__body {
    background-color: #ffffff70;
    padding: 0 0.5rem 3rem;

    &--projects {
      padding-top: 2rem;
      padding-bottom: 5rem;
    }

    &--bottom {
      padding-bottom: 6rem;
    }
  }

  &__title {
    padding: 1.75rem 0;
    margin: 0;
    color: var(--clr-white);
    font-size: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;

    &--header {
      padding-top: 3.5rem;
    }
  }

  &__tagline {
    color: var(--clr-white);
    font-size: 1.2rem;
    line-height: 1.7;
    margin: 0;
  }

  &__link {
    text-decoration: underline;
    color: var(--clr-white);
    font-size: 1.25rem;
  }

  &__lesson-list,
  &__course-list {
    display: grid;
    max-width: var(--max-width);
    padding: 0;
    padding-bottom: 1rem;
    margin: 0 auto;
    list-style-type: none;
  }

  &__lesson-list {
    gap: 0.125rem;

    @media (min-width: 300px) {
        gap: 0;
    }
  }

  &__course-list {
    gap: 1rem;
  }

  &__message {
    padding: 0;
    margin: 0;
    font-size: 1.125rem;

    a {
      color: var(--clr-accent);
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.scroll-target {
  position: absolute;
  top: -6.5rem;
}
