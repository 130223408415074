@import 'assets/scss/common.scss';

.ClassMicsToggle__wrapper {
    position: absolute;
    right: 1.5em;

    width: 100%;
    height: 100%;

    .switch-button {
        background-repeat: no-repeat;
        background-size: contain;

        width: 70%;
        height: 70%;

        &.enabled {
            background-image:url(./images/mic-global-enabled-on.png);

            &:hover {
                background-image:url(./images/mic-global-enabled-hover.png);
            }
        }

        &.disabled {
            background-image:url(./images/mic-global-disabled-on.png);

            &:hover {
                background-image:url(./images/mic-global-disabled-hover.png);
            }
        }
    }

    .action-text {
        position: absolute;
        top: 3.2em;
        right: 1em;

        color: white;
        font-size: 0.3em;
        width: 6em;
        text-align: center;
    }

}